import axios from "axios";
import { Outlet } from "react-router-dom";
import { Footer } from "./footer.js";
import NavBar from "./nav-bar.js";

axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;
// if (token) {
//   axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// }

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("refresh-token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  error => Promise.reject(error)
);

export const Layout = () => {
  return (
    <>
      <NavBar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
