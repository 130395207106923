import { createSlice } from "@reduxjs/toolkit";
import { getCartDetail } from "../api/index.js";

const initialState = {
  carts: [],
  total_amount: 0
};

const productCartList = createSlice({
  name: "productWishList",
  initialState,
  reducers: {
    cartListInfo: (state, action) => {
      state.carts = action?.payload?.carts;
      state.total_amount = action?.payload?.total_amount;
    },
    cartListInfoError: (state, action) => {
      state.carts = [];
      state.total_amount = 0;
    },
  },
});

export const { cartListInfo, cartListInfoError } = productCartList.actions;
export default productCartList.reducer;

export const initialCartListCheck = () => async (dispatch) => {
  getInitialCartList(dispatch);
};

const getInitialCartList = async (dispatch) => {
  getCartDetail()
    .then((data) => {
      if (data.status === "success") {
        dispatch(
          cartListInfo({
            carts: data?.cart,
            total_amount: data?.total_amount,
          })
        );
      } else {
        dispatch(cartListInfoError());
      }
    })
    .catch((data) => {
      dispatch(cartListInfoError());
    });
};
