import React from "react";
import "./App.css";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./Home/Home.js";
import ForgotPassword from "./SigninComponents/ForgotPassword.js";
import ProtectedRoute from "./SigninComponents/ProtectedRoute.js";
import SigninComponent from "./SigninComponents/SigninComponent.js";
import { SignupComponent } from "./SigninComponents/SignupComponent.js";
import { AccountSetting } from "./account/account-setting.js";
import { AccountAddress } from "./account/address.js";
import { AccountOrder } from "./account/orders.js";
import ViewCart from "./cart/ViewCart.js";
import Wishlist from "./cart/Wishlist.js";
import { CheckOutPage } from "./cart/checkout.js";
import "./index.css";
import { AccountLayout } from "./layout/account-layout.js";
import { Layout } from "./layout/index.js";
import ProductDetail from "./product/detail/ProductDetail.js";
import { ProductListPage } from "./product/list/ProductList.js";
import Termsofservice from "./footerContents/Termsofservice.js";

import { initialSessionCheck } from "./reducers/LoggedInReducer.js";
import { initialWishListCheck } from "./reducers/WishListReducer.js";
import { initialCartListCheck } from "./reducers/cartListReducer.js";
import Privacypolicy from "./footerContents/Privacypolicy.js";
import ShippingPolicy from "./footerContents/ShippingPolicy.js";
import HelpCenter from "./footerContents/HelpCenter.js";
import ProductReturn from "./footerContents/ProductReturn.js";
// import pageLoader from "./assets/spinner-4.gif";
import BeatLoader from "react-spinners/BeatLoader.js";
import OrderTracker from "./orderTracking/OrderTracker.js";
import Contactus from "./footerContents/Contactus.js";

function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state?.userLogin);

  useEffect(() => {
    dispatch(initialSessionCheck());
    dispatch(initialWishListCheck());
    dispatch(initialCartListCheck());
  }, [dispatch]);

  if (!userLogin?.isPageLoaded) {
    return (
      <div className="justify-content-center align-items-center d-flex vh-100">
      {/* <img src={pageLoader} alt="loader" /> */}
      <BeatLoader size={40}/>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route
          path="/login"
          element={
            userLogin?.loggedIn ? <Navigate to="/" /> : <SigninComponent />
          }
        />
        <Route
          path="/register"
          element={
            userLogin?.loggedIn ? <Navigate to="/" /> : <SignupComponent />
          }
        />
        <Route
          path="/forgot-password"
          element={
            userLogin?.loggedIn ? <Navigate to="/" /> : <ForgotPassword />
          }
        />
        <Route path="/product-detail/:id" element={<ProductDetail />} />
        <Route path="/products/:type?" element={<ProductListPage />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route
          path="/cart"
          element={
            <ProtectedRoute>
              <ViewCart />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/wishlist"
          element={
            <ProtectedRoute>
              <Wishlist />
            </ProtectedRoute>
          }
        ></Route>
        <Route path="account" element={<AccountLayout />}>
          <Route index element={<AccountOrder />} />
          <Route path="setting" element={<AccountSetting />} />
          <Route path="order" element={<AccountOrder />} />
          <Route path="address" element={<AccountAddress />} />
          <Route path="order/track" element={<OrderTracker />} />
        </Route>
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <CheckOutPage />
            </ProtectedRoute>
          }
        ></Route>
        {/* <Route
          path="/track"
          element={
            <ProtectedRoute>
              <OrderDetails7 />
            </ProtectedRoute>
          }
        ></Route> */}
        <Route path="/termsofservice" element={<Termsofservice />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/shippingpolicy" element={<ShippingPolicy />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/productreturn" element={<ProductReturn />} />
      </Route>
    </Routes>
  );
}

export default App;
