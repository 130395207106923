import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getProductsList, getProductPageCategories } from "../../api/index.js";
import { ProductCard } from "./product-card.js";
import { Pagination } from "../../common-component/pagination.js"
import { keyBy } from "lodash"

export const ProductListPage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [totalContentSize, setTotalContentSize] = useState(0);
  const params = useParams()
  const category_slug = params?.['type']

  useEffect(() => {
    getProducts()
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1)
    getProducts()
  }, [category_slug]);

  const getProducts = () => {
    const skip = pageSize * (currentPage - 1)
    const limit = pageSize
    getProductsList({ skip, limit, category_slug }).then((data) => {
      setProducts(data?.products);
      setTotalContentSize(data?.total_products);
    });
  }

  useEffect(() => {
    getProductPageCategories().then((data) => {
      setCategories(data)
    });
  }, []);

  return (
    <>
      {/* <div className="mt-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-dark">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/products" className="text-dark">Shop</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div> */}
      {/* section */}
      <div className="mt-8 mb-lg-14 mb-8">
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row gx-10">
            {/* col */}
            <aside className="col-lg-3 col-md-4 mb-6 mb-md-0">
              <div
                className="offcanvas offcanvas-start offcanvas-collapse w-md-50"
                tabIndex={-1}
                id="offcanvasCategory"
                aria-labelledby="offcanvasCategoryLabel"
              >
                <div className="offcanvas-header d-lg-none">
                  <h5 className="offcanvas-title" id="offcanvasCategoryLabel">
                    Filter
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div className="offcanvas-body ps-lg-2 pt-lg-0">
                  <div className="mb-8">
                    {/* title */}
                    <h5 className="mb-3">Categories</h5>
                    {/* nav */}
                    <ul className="nav nav-category" id="categoryCollapseMenu">
                      {
                        categories?.map((category,index) => (
                          <li className="nav-item border-bottom w-100" key={index}>
                            {/* <span
                              className="nav-link collapsed text-dark"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${category?.slug}`}
                              aria-expanded="false"
                              aria-controls={`${category?.slug}`}
                            > */}
                              <Link to={`/products/${category?.slug}`} className="nav-link">
                                          {category?.name}
                                        </Link>
                              {/* {category?.name} */}
                              {/* <i className="feather-icon icon-chevron-right" /> */}
                            {/* </span> */}
                            {/* accordion collapse */}
                            {/* <div
                              id={`${category?.slug}`}
                              className="accordion-collapse collapse"
                              data-bs-parent="#categoryCollapseMenu"
                            >
                              <div>
                                {/* nav */}
                                {/* <ul className="nav flex-column ms-3">

                                  {
                                    category?.subcategory?.map(subCategory => (
                                      <li className="nav-item">
                                        <Link to={`/products/${subCategory?.slug}`} className="nav-link">
                                          {subCategory?.name}
                                        </Link>
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            </div> */}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </aside>
            <section className="col-lg-9 col-md-12">
              {/* card */}
              <div className="card mb-4 bg-light border-0">
                {/* card body */}
                <div className="card-body p-5">
                  <h2 className="mb-0 fs-1">Men's T-shirts & polos</h2>
                </div>
              </div>
              {/* list icon */}
              <div className="d-lg-flex justify-content-between align-items-center">
                <div className="mb-3 mb-lg-0">
                  <p className="mb-0">
                    <span className="text-dark">{totalContentSize} </span>
                    Products found
                  </p>
                </div>
                {/* icon */}
                <div className="d-md-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <div>
                        <a href="shop-list.html" className="text-muted me-3"><i className="bi bi-list-ul" /></a>
                        <a href="shop-grid.html" className="me-3 active"><i className="bi bi-grid" /></a>
                        <a href="shop-grid-3-column.html" className="me-3 text-muted"><i className="bi bi-grid-3x3-gap" /></a>
                      </div> */}
                    <div className="ms-2 d-lg-none">
                      <a
                        className="btn btn-outline-gray-400 text-muted"
                        data-bs-toggle="offcanvas"
                        href="#offcanvasCategory"
                        role="button"
                        aria-controls="offcanvasCategory"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={14}
                          height={14}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-filter me-2"
                        >
                          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                        </svg>
                        Filters
                      </a>
                    </div>
                  </div>
                  <div className="d-flex mt-2 mt-lg-0">
                    <div className="me-2 flex-grow-1">
                      {/* select option */}
                      <select className="form-select">
                        <option defaultValue={50}>Show: 50</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                      </select>
                    </div>
                    {/* select option */}
                    {/* <div>
                        <select className="form-select">
                          <option selected>Sort by: Featured</option>
                          <option value="Low to High">Price: Low to High</option>
                          <option value="High to Low">Price: High to Low</option>
                          <option value="Release Date">Release Date</option>
                          <option value="Avg. Rating">Avg. Rating</option>
                        </select>
                      </div> */}
                  </div>
                </div>
              </div>
              {/* row */}
              <div className="row g-4 row-cols-xl-4 row-cols-lg-3 row-cols-2 row-cols-md-2 mt-2">
                {products?.map((product, index) => (
                  <ProductCard product={product} key={index}/>
                ))}
              </div>
              {
                totalContentSize > pageSize ? (
                  <div className="row mt-8">
                    <div className="col">
                      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalContentSize={totalContentSize} pageSize={pageSize} />
                    </div>
                  </div>
                ) : null
              }
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
