import * as bootstrap from 'bootstrap';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddressComponent from "../account/AddressComponent";
import { addressList, checkoutGetOrder } from "../api";
window.bootstrap = bootstrap

export const CheckOutPage = () => {
  const locationValue = useLocation();
  const navigation = useNavigate();
  const checkoutId = locationValue?.["state"]?.["checkout_id"];

  const userLogin = useSelector((state) => state?.userLogin);

  // const [addressLists, setAddressList] = useState();
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressEnable, setAddressEnable] = useState(false);

  // const loadAddressList = () => {
  //   addressList().then((re) => setAddressList(re?.data?.address));
  // };

  // useEffect(() => {
  //   loadAddressList();
  // }, []);

  const placeOrder = () => {
    if (!checkoutId) {
      console.log("checkout not allowed!");
      return;
    }
    if (!selectedAddress) {
      console.log("No Address Selected!");
      return;
    }
    checkoutGetOrder({
      checkout_id: checkoutId,
      address_id: selectedAddress?.address?.id,
    }).then((res) => {
      if(res?.order_id)
        initPayment(res);
    });
  };

  const initPayment = (res) => {
    let options = {
      key: `${process.env.REACT_APP_RAZORPAY_API}`,
      name: `${process.env.REACT_APP_RAZORPAY_NAME}`,
      description: "Test Payment",
      order_id: res?.order_id,
      prefill: {
        name: userLogin?.currentUser?.name,
        email: userLogin?.currentUser?.email,
        contact: "91"+userLogin?.currentUser?.mobile,
      },
      notes: {
        testing: "testing detail",
      },
      theme: {
        image_padding: false,
        color: "#00804a",
      },
    };
    options = {
      ...options,
      handler: (response) => paymentSuccessful(response),
    };
    const razerPay = new window.Razorpay(options);
    razerPay.open();
  };

  const paymentSuccessful = (response) => {
    navigation("/account/order")
  };

  const chooseselectedAddress = (address,index) => {
    setSelectedAddress({
      address : address,
      index : index
    });
    setAddressEnable(true);
    if(document.getElementsByClassName('accordion-collapse collapse')[0].className.includes('show')){
      document.getElementsByClassName('accordion-collapse collapse')[0].classList.add('hide');
      document.getElementsByClassName('accordion-collapse collapse')[0].classList.remove('show');
      document.getElementsByClassName('accordion-collapse collapse')[1].classList.add('show');
    }else{
      document.getElementsByClassName('accordion-collapse collapse')[1].classList.add('show');
      document.getElementsByClassName('accordion-collapse collapse')[0].classList.remove('hide');
    }
    // collapsed.classList.add('hide');
  };

  const addressChange = () => {
    setAddressEnable(false);
  }

  return (
    <div>
      <section className="mb-lg-14 mb-8 mt-8">
        <div className="container">
          <div>
            {/* row */}
            <div className="row">
              <div className="col-lg-12 col-md-6">
                {/* accordion */}
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {/* accordion item */}
                  <div className="accordion-item py-4 addressAccordian">
                    <div className="d-flex justify-content-between align-items-center">
                      <a href="#"
                      onClick={()=>setAddressEnable(false)}
                        className="fs-5 text-inherit collapsed h4"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="true"
                        aria-controls="flush-collapseOne">
                        <i className="feather-icon icon-map-pin me-2 text-muted" />
                        Add delivery address
                      </a>
                      <button
                        // href="#"
                        className={`btn btn-primary text-bg-dark ${addressEnable ? 'disabled' : '' } `}
                        data-bs-toggle="modal"
                        data-bs-target="#addAddressModal1"
                        // disabled
                      >
                        Add a new address
                      </button>
                    </div>
                    <div id="flush-collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionFlushExample">
                      <div className="mt-5">
                        <AddressComponent selectedAddress={chooseselectedAddress} selectedAddressIndex={selectedAddress} checkoutId={checkoutId}/></div>
                    </div>
                  </div>

                  {/* accordion item */}
                  <div className="accordion-item py-4 paymentAccordian">
                    <a
                      href="#"
                      className="text-inherit h5"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="true"
                      aria-controls="flush-collapseTwo"
                    >
                      <i className="feather-icon icon-credit-card me-2 text-muted" />
                      Payment Method
                      {/* collapse */}
                    </a>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="mt-5">
                        <div>
                          <div className="card card-bordered shadow-none mb-2">
                            {/* card body */}
                            <div className="card-body p-6">
                              {/* check input */}
                              <div className="d-flex">
                                <div className="form-check">
                                  <input
                                    className="form-check-input text-bg-dark"
                                    type="radio"
                                    checked
                                    name="flexRadioDefault"
                                    id="payoneer"
                                  />
                                  <label
                                    className="form-check-label ms-2"
                                    htmlFor="payoneer"
                                  />
                                </div>
                                <div>
                                  {/* title */}
                                  <h5 className="mb-1 h6">Pay with Razorpay</h5>
                                  <p className="mb-0 small">
                                    You will be redirected to Razorpay website
                                    to complete your purchase securely.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* card */}
                          <div className="card card-bordered shadow-none">
                            <div className="card-body p-6">
                              {/* check input */}
                              <div className="d-flex">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    disabled
                                    name="flexRadioDefault"
                                    id="cashonDelivery"
                                  />
                                  <label
                                    className="form-check-label ms-2"
                                    htmlFor="cashonDelivery"
                                  />
                                </div>
                                <div>
                                  {/* title */}
                                  <h5 className="mb-1 h6">Cash on Delivery</h5>
                                  <p className="mb-0 small">
                                    Cod is not available
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Button */}
                          <div className="mt-5 d-flex justify-content-end">
                            <Link
                              to={"/cart"}
                              className="btn btn-outline-gray-400 text-muted"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseOne"
                              aria-expanded="false"
                              aria-controls="flush-collapseOne"
                              onClick={addressChange}
                            >
                              Prev
                            </Link>
                            <button
                              onClick={placeOrder}
                              disabled={!checkoutId || !selectedAddress?.address?.id}
                              className="btn btn-primary ms-2 text-bg-dark"
                            >
                              Place Order
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
