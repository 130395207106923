import * as bootstrap from 'bootstrap';
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addAddress, addressList, deleteAddress, editAddress } from "../api";
import './addressComponent.css';
import address_empty from "../assets/address_empty.png";
window.bootstrap = bootstrap

const intialState = {
    name: "",
    address: "",
    city: "",
    pincode: "",
    mobile: ""
};


const AddressComponent = (props) => {

    const [addressData, setAddressData] = useState([]);
    const [address, setAddress] = useState(intialState);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorFlag, setErrorFlag] = useState(false);
    const [selectedAddress,setSelectedAddress] = useState(null);

    const setAddressValues = (event) => {
        setErrorFlag(false);
        setErrorMsg('');
        setAddress((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
    };

    const closeDialog = () => {
        setAddress(intialState);
        setErrorFlag(false);
    }

    const addressHandler = () => {
        if(address?.id){
            editAddress(address).then((res)=> {
                if(res?.code === '200'){
                    loadAddress();
                    setErrorFlag(false);
                    setAddress(intialState);
                    const address_modal = document.getElementById('addAddressModal1');
                    const modal = bootstrap.Modal.getInstance(address_modal);
                    document.getElementsByClassName('modal-backdrop')[0].remove();
                    modal.hide();
                    toast.success(`${res?.message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }else {
                    setErrorMsg(res?.response?.data?.error);
                    setErrorFlag(true);
                }
            });
        }
        else if (address.name !== '' && address.address !== '' && address.city !== '' && address.pincode !== '' && address.mobile !== '') {
            addAddress(address).then((res) => {
                if (res?.status === 'success') {
                    loadAddress();
                    // props.addressFlag(true);
                    setErrorFlag(false);
                    setAddress(intialState);
                    const address_modal = document.getElementById('addAddressModal1');
                    const modal = bootstrap.Modal.getInstance(address_modal);
                    document.getElementsByClassName('modal-backdrop')[0].remove();
                    modal.hide();
                    toast.success(`${res?.message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                } else {
                    setErrorMsg(res?.response?.data?.error);
                    setErrorFlag(true);
                }
            });
        } else {
            setErrorFlag(true);
        }
    };

    const errorCode = (key) => {
        let error = errorMsg.filter((re) => re.field === key)
        return <div className="showError">{error[0]?.message}</div>
    }

    const editAddressHandler = (event) => {
        setAddress(addressData[event.target.value]);
        event.stopPropagation();
    }

    const deleteAddressHandler = (event) => {
        const params = {
            id : addressData[event.target.value]?.id
        }
        if(addressData[event.target.value]?.id){
            deleteAddress(params).then((re)=>{
                if(re?.code === '200'){
                    loadAddress();
                    // setErrorFlag(false);
                    toast.success(`${re?.message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }else {
                    toast.error(`${re?.message}`, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                        });
                }
            });
        }
    }

    const chooseAddress = (address,index) => {
        address.stopPropagation();
        props.selectedAddress(addressData[index]);
        setSelectedAddress(index);
      };

    const loadAddress = () => {
        addressList().then((response) => { setAddressData(response?.data?.address) });
    }

    useEffect(() => {
        loadAddress();
    }, []);

    return (
        <>
            <div className="modal fade" id="addAddressModal1" tabindex="-1" aria-labelledby="addAddressModalLabel" aria-hidden="true" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-6">
                            <div className="d-flex justify-content-between mb-5">
                                <div>
                                    <h5 className="mb-1" id="addAddressModalLabel">New Shipping Address</h5>
                                    <p className="small mb-0">Add new shipping address for your order delivery.</p>
                                </div>
                                <div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeDialog}></button>
                                </div>
                            </div>

                            {errorFlag ? <div className="showError mb-3">
                                Please fill all the fields.
                            </div> : null}

                        <div className="row g-3">
                            <div className="col-12">
                                <input type="text" className="form-control" placeholder="Name" aria-label="Name" required="" name="name" value={address.name} onChange={setAddressValues} />
                                <div className="mb-3">
                                        {errorMsg ? errorCode('name') : null}
                                    </div>

                                </div>
                            <div className="col-12">
                                <input type="text" className="form-control" placeholder="Address Line 1" value={address.address} onChange={setAddressValues} name="address" />
                                <div className="mb-3">
                                        {errorMsg ? errorCode('address') : null}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control" placeholder="Address Line 2" value={address.address} onChange={setAddressValues} name="address" />
                                    <div className="mb-3"></div>
                                    {errorMsg ? errorCode('address') : null}
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control" placeholder="City" value={address.city} onChange={setAddressValues} name="city" />
                                    <div className="mb-3">
                                        {errorMsg ? errorCode('city') : null}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <select className="form-select" name="country" disabled>
                                        <option selected="">India</option>
                                        {/* <option value="1">UK</option>
                           <option value="2">USA</option>
                           <option value="3">UAE</option> */}
                                    </select>
                                    <div className="mb-3">
                                        {errorMsg ? errorCode('country') : null}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <input type="text" className="form-control" placeholder="Pin Code" name="pincode" value={address.pincode} onChange={setAddressValues} />
                                    <div className="mb-3">
                                        {errorMsg ? errorCode('pincode') : null}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <input type="number" className="form-control" placeholder="Phone Number" name="mobile" value={address.mobile} onChange={setAddressValues} />
                                    <div className="mb-3">
                                        {errorMsg ? errorCode('mobile') : null}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check d-none">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault">Set as Default</label>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-end gap-3">
                                    <button type="button" className="btn btn-outline-dark text-bg-white" data-bs-dismiss="modal" onClick={closeDialog}>Cancel</button>
                                    <button className="btn btn-primary text-bg-dark" type="button" name="saveAddress" onClick={addressHandler}>Save Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">

                {
addressData?.length > 0 ? <>

{addressData?.map((addList, index) =>
    <div className="col-lg-5 col-xxl-4 col-12 mb-4">
        <div className="card">

        <button type="button" className={`btn card card-body p-6 text-start ${selectedAddress === index ? 'border-2' : 'border-0'} ${window.location.pathname !== '/account/address' ? 'card-product' : 'autoCursor' } `} onClick={ window.location.pathname !== '/account/address' ? (event) => chooseAddress(event, index) : null }>
                {/* <div className="form-check mb-4">
  <input
    className="form-check-input"
    type="radio"
    name="flexRadioDefault"
    id="homeRadio"
    value={index}
    onClick={(event)=>chooseDefaultAddress(event,index)}
    // checked={defaultAddressFlag}
    // defaultChecked
  />
  <label
    className="form-check-label text-dark fw-semibold"
    htmlFor="homeRadio"
  >
    Home
  </label>
</div> */}
                {/* address */}
                <p className="mb-6">
                    {addList.name}
                    <br />
                    {addList.address},
                    <br />
                    {addList.city},
                    <br />
                    {addList.pincode}
                    <br />
                    {addList.mobile}
                </p>
                {/* btn */}
                {/* <a href="/account/address" className="btn btn-info btn-sm">
  Default address
</a> */}
                <div className="mt-4">
                    <button className="text-inherit bg-transparent border-0"
                        data-bs-toggle="modal"
                        data-bs-target="#addAddressModal1"
                        onClick={editAddressHandler}
                        value={index}>
                        Edit
                    </button>
                    { !props.checkoutId && <button
                        className="text-danger ms-3 bg-transparent border-0"
                        value={index}
                        onClick={deleteAddressHandler}
                    >
                        Delete
                    </button> }
                </div> 
            </button>
        </div>
    </div>
)}

</> : 
<div className="text-center p-4">
          <img alt="" src={address_empty} className="w-20 h-20"></img>
          <h3 className="pt-7">Your Address box is Empty Now..!</h3>
        </div>
        } 

               
            </div>

            <ToastContainer />

        </>
    )
}

export default AddressComponent;