import React from "react";
// import bootstrap from 'boots';
import * as bootstrap from 'bootstrap';
import AddressComponent from "./AddressComponent";
window.bootstrap = bootstrap
// import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.js';
// import { Modal } from 'bootstrap/dist/js/bootstrap.bundle.js';

export const AccountAddress = () => {

  // const intialState = {
  //   name: "",
  //   address: "",
  //   city: "",
  //   pincode: "",
  //   mobile: ""
  // };

  // const [addressData, setAddressData] = useState([{}]);
  // const [address, setAddress] = useState(intialState);
  // const [addressFlag, setAddressFlag] = useState(false);
  // const [errorMsg, setErrorMsg] = useState('');
  // const [errorFlag, setErrorFlag] = useState(false);
  // const [selectedAddress, setSelectedAddress] = useState({});

  // const setAddressValues = (event) => {
  //   setErrorFlag(false);
  //   setErrorMsg('');
  //   setAddress((prev) => ({
  //     ...prev,
  //     [event.target.name]: event.target.value,
  //   }));
  // };

  // const addressHandler = () => {
  //   if (address.name !== '' && address.address !== '' && address.city !== '' && address.pincode !== '' && address.mobile !== '') {
  //     addAddress(address).then((res) => {
  //       if (res?.status === 'success') {
  //         loadAddress();
  //         setErrorFlag(false);
  //         setAddress(intialState);
  //         const address_modal = document.getElementById('addAddressModal1');
  //         const modal = bootstrap.Modal.getInstance(address_modal);
  //         document.getElementsByClassName('modal-backdrop')[0].remove()
  //         modal.hide();
  //       } else {
  //         setErrorMsg(res?.response?.data?.error);
  //         setErrorFlag(true);
  //       }
  //     });
  //   } else {
  //     setErrorFlag(true);
  //   }
  // };

  // const closeDialog = () => {
  //   setAddress(intialState);
  //   setErrorFlag(false);
  // }

  // const loadAddress = () => {
  //   addressList().then((response) => { setAddressData(response?.data?.address) });
  // }

  // const saveAddressHandler = (flag) => {
  //   setAddressFlag(flag);
  // }

  // useEffect(() => {
  //   loadAddress();
  // }, []);

  // useEffect(() => {
  //   loadAddress();
  // }, [addressFlag]);



  // const errorCode = (key) => {
  //   let error = errorMsg.filter((re) => re.field === key)
  //   return <div className="showError">{error[0]?.message}</div>
  // }

  // const editAddress = (event) => {
  //   setAddress(addressData[event.target.value]);
  // }

  // const deleteAddress = (event) => {
  //   console.log('addressId',addressData[event.target.value] );
  // }

  // const chooseDefaultAddress = (event,index) => {
  //   setSelectedAddress(addressData[index]);
  //   // event.preventDefault();
  //   // const radioInputs = document.getElementsByName('flexRadioDefault');
  //   // for(let i = 0; i < radioInputs?.length; i++) {
  //   //   radioInputs[i].checked = false;
  //   // }
  //   // document.getElementsByName('flexRadioDefault')[index].checked = true;
  //   // event.preventDefault();
  //   // setDefaultAddressFlag(!defaultAddressFlag);
  // }

  return (
    <>
      <div className="d-flex justify-content-between mb-6">
        {/* heading */}
        <h2 className="mb-0">Address</h2>
        {/* button */}
        <button
          className="btn btn-outline-primary text-bg-dark"
          data-bs-toggle="modal"
          data-bs-target="#addAddressModal1"
        >
          Add a new address
        </button>
      </div>

      <AddressComponent />

      {/* <div class="modal fade" id="addAddressModal1" tabindex="-1" aria-labelledby="addAddressModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body p-6">
              <div class="d-flex justify-content-between mb-5">
                <div>
                  <h5 class="mb-1" id="addAddressModalLabel">New Shipping Address</h5>
                  <p class="small mb-0">Add new shipping address for your order delivery.</p>
                </div>
                <div>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeDialog}></button>
                </div>
              </div>

              {errorFlag ? <div className="showError mb-3">
                Please fill all the fields.
              </div> : null}

              <div class="row g-3">
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="Name" aria-label="Name" required="" name="name" value={address.name} onChange={setAddressValues} />
                  <div className="mb-3">
                    {errorMsg ? errorCode('name') : null}
                  </div>

                </div>
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="Address Line 1" value={address.address} onChange={setAddressValues} name="address" />
                  <div className="mb-3">
                    {errorMsg ? errorCode('address') : null}
                  </div>
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="Address Line 2" value={address.address} onChange={setAddressValues} name="address" />
                  <div className="mb-3"></div>
                  {errorMsg ? errorCode('address') : null}
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="City" value={address.city} onChange={setAddressValues} name="city" />
                  <div className="mb-3">
                    {errorMsg ? errorCode('city') : null}
                  </div>
                </div>
                <div class="col-12">
                  <select class="form-select" name="country" disabled>
                    <option selected="">India</option>
                    {/* <option value="1">UK</option>
                           <option value="2">USA</option>
                           <option value="3">UAE</option> */}
      {/* </select>
                  <div className="mb-3">
                    {errorMsg ? errorCode('country') : null}
                  </div>
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="Pin Code" name="pincode" value={address.pincode} onChange={setAddressValues} />
                  <div className="mb-3">
                    {errorMsg ? errorCode('pincode') : null}
                  </div>
                </div>
                <div class="col-12">
                  <input type="number" class="form-control" placeholder="Phone Number" name="mobile" value={address.mobile} onChange={setAddressValues} />
                  <div className="mb-3">
                    {errorMsg ? errorCode('mobile') : null}
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    <label class="form-check-label" for="flexCheckDefault">Set as Default</label>
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-end gap-3">
                  <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" onClick={closeDialog}>Cancel</button>
                  <button class="btn btn-primary" type="button" name="saveAddress" onClick={addressHandler}>Save Address</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="row">
        {addressData?.map((addList,index) =>
          <div className="col-lg-5 col-xxl-4 col-12 mb-4">
            <div className="card">
              <div className="card-body p-6">
                {/* <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="homeRadio"
                    value={index}
                    onClick={(event)=>chooseDefaultAddress(event,index)}
                    // checked={defaultAddressFlag}
                    // defaultChecked
                  />
                  <label
                    className="form-check-label text-dark fw-semibold"
                    htmlFor="homeRadio"
                  >
                    Home
                  </label>
                </div> */}
      {/* address */}
      {/* <p className="mb-6">
                  {addList.name}
                  <br />
                  {addList.address},
                  <br />
                  {addList.city},
                  <br />
                  {addList.pincode}
                  <br />
                  {addList.mobile}
                </p> */}
      {/* btn */}
      {/* <a href="/account/address" className="btn btn-info btn-sm">
                  Default address
                </a> */}
      {/* <div className="mt-4">
                  <button className="text-inherit bg-transparent border-0" 
                  data-bs-toggle="modal"
                  data-bs-target="#addAddressModal1" 
                  onClick={editAddress} 
                  value={index}>
                    Edit
                  </button>
                  <button
                    className="text-danger ms-3 bg-transparent border-0"
                    value={index}
                    onClick={deleteAddress}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div> */}
    </>
  );
};
