import React from "react";
import empty_carts from "../assets/empty_carts.png";

export const EmptyCart = () => {
  return (
    <div className="col-lg-8 col-md-7 text-center p-4">
      <img
        src={empty_carts}
        alt=""
        className="w-20 h-20"
      ></img>
      <h3 className="pt-7">Your bag is Currently empty..!</h3>
    </div>
  );
};

