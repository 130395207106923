export default function Privacypolicy() {
    return (
        <div className="container">
            <h3 className="text-center pt-4">Privacy Policy</h3>
            <p className="ps-10 pe-10">
                Zebroz Privacy Policy
                We respect your right to privacy. We collect only the information necessary for us to complete your order or to contact you regarding the status of your current order. The information we collect includes your name, email address, telephone number, shipping address, and billing address.
            </p>

            <p className="ps-10 pe-10">
                Zebroz does not store your credit card or online bank account information.
            </p>

            <p className="ps-10 pe-10">
                Zebroz will not rent or sell your name or personal information.
            </p>

            <p className="ps-10 pe-10">
                Zebroz utilizes "cookies" to help recognize you as a repeat visitor and to track traffic patterns on our site. This information is completely anonymous. We use this information only to improve the user-friendliness.
            </p>

            <p className="ps-10 pe-10">
                Zebroz reserves the right to update this privacy policy at anytime. Updates to our privacy policy will be sent to the email address that you have provided us.
            </p>

            <p className="ps-10 pe-10">
                COLLECTION OF PERSONAL INFORMATION: We collect "personal" information from you when you provide it to us. For example, if you purchase a product from us, we may collect your name, mailing address, telephone number and email address. If you sign up to receive a newsletter, we may collect your email address and telephone number. If you take advantage of special services offered by us, we may collect other personal information about you. We use your personal information for internal purposes such as processing, keeping you informed of your order as well as notify you regarding new product launches or ongoing promotions.
            </p>

            <p className="ps-10 pe-10">
                DISCLOSURE OF PERSONAL INFORMATION: Under no circumstances do we rent, trade or share your PII that we have collected with any other company for their marketing purposes without your consent. We reserve the right to communicate your personal information to any third party that makes a legally-compliant request for its disclosure. Otherwise, however, we will not disclose your name, address and other information which identifies you personally to any third party without your consent. We reserve the right to collect general demographic and other anonymous information that does not personally identify you. This information is not associated with your personally identifiable information and cannot be linked to you personally. However, in case of a situation wherein we are obliged to comply with law or a statutory obligation or a legal process, we would be compelled to provide information about a customer.
            </p>

            <p className="ps-10 pe-10">
                Why are COOKIES important: We use cookies and other technologies such as pixel tags and clear gifs to store certain types of information each time you visit any page on our website. Cookies enable this website to recognize the information you have consented to give to this website and help us determine what portions of this website are most appropriate for your professional needs. We may also use cookies to serve advertising banners to you. These banners may be served by us or by a third party on our behalf. These cookies will not contain any personal information.
            </p>

            <p className="ps-10 pe-10">
                NPII advertising: We use third-party service providers to serve ads on our behalf across the Internet and sometimes on this site. They may collect anonymous information about your visits to our website, and your interaction with our products and services. They may also use information about your visits to this and other websites to target advertisements for goods and services. This anonymous information is collected through the use of a pixel tag, which is industry standard technology used by most major websites. No personally identifiable information is collected or used in this process. They do not know the name, phone number, address, email address, or any personally identifying information about the user.
            </p>

        </div>
    )
}