import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginUserData } from "../api/index.js";
import { initialSessionCheck } from "../reducers/LoggedInReducer.js";
import { initialWishListCheck } from "../reducers/WishListReducer.js";
import { initialCartListCheck } from "../reducers/cartListReducer.js";
import './signinComponent.css';
import logo from "../assets/zebroz_logo.png";

export default function SigninComponent() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Please enter correct Username/Password');
  const [eyeFlag, setEyeFlag] = useState(false);
  const navigatePath = useNavigate();
  const { state: locationState } = useLocation();

  const getInputValues = (event) => {
    setErrorFlag(false);
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const setToken = (token) => {
    localStorage.setItem("refresh-token", token);
    localStorage.setItem("lastLoginTime", new Date(Date.now()).getTime());
  };

  const loginUser = async () => {
    if (userData.email !== '' && userData.password !== '') {
      // setLoader(true);
      let res = await loginUserData(userData);

      // .then((res) => {
        dispatch(initialSessionCheck());
        if (res?.code === "200") {
          setToken(res?.data?.token);
          // setLoader(false);
          setTimeout(() => {
            dispatch(initialWishListCheck());
            dispatch(initialCartListCheck());
            // navigatePath(`${locationState?.redirectTo?locationState?.redirectTo:"/"}`);
          }, 500);
        } else {
          setToken(null);
          setErrorMsg(res?.response?.data?.message)
          setErrorFlag(true);
        }
      // })
      // axios.post(`login`,userData).then((res) => {
      // }).catch((err)=> { 
      //   if (err?.response?.data?.code === "422") {
      //     setToken(null);
      //     setErrorMsg(err?.response?.data?.message)
      //     setErrorFlag(true);
      //   }
      //  });
    } else {
      setErrorFlag(true);
      setErrorMsg(errorMsg);
    }
  };

  return (
    <section className="my-lg-14 my-8">

      <div className="container">
        <div className="row justify-content-center align-items-center">
          {/* <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
            <img
              src={logo}
              alt="logo"
              className="img-fluid"
            />
          </div> */}
          <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
            <div className="mb-lg-3 mb-2">
              <h1 className="mb-1 h2 fw-bold">Signin to Zebroz</h1>
              <p>Welcome back to Zebroz..! Enter your email to get started.</p>
            </div>
            { errorFlag ? <div className="mb-3 errorClass">{errorMsg}</div> : null }
            <form className="needs-validation">
              <div className="row g-3">
                <div className="col-12">
                  <label
                    htmlFor="formSigninEmail"
                    className="form-label visually-hidden"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control border-secondary"
                    id="formSigninEmail"
                    placeholder="Email"
                    name="email"
                    required
                    onChange={getInputValues}
                  />
                  <div className="invalid-feedback">
                    Please enter valid email address.
                  </div>
                </div>
                <div className="col-12">
                  <div className="password-field position-relative">
                    <label
                      htmlFor="formSigninPassword"
                      className="form-label visually-hidden"
                    >
                      Password
                    </label>
                    <div className="password-field position-relative">
                      <input
                        type={eyeFlag ? 'text' : 'password'}
                        className="form-control fakePassword border-secondary"
                        id="formSigninPassword"
                        placeholder="*****"
                        name="password"
                        onChange={getInputValues}
                        required
                      />
                      <span>
                        <i className={eyeFlag ? 'bi bi-eye passwordToggler' : 'bi bi-eye-slash passwordToggler'} onClick={()=>setEyeFlag(!eyeFlag)} />
                      </span>
                      <div className="invalid-feedback">
                        Please enter valid password.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-check" style={{ display: 'none' }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Remember me
                    </label>
                  </div>
                  <div>
                    Forgot password?
                    <Link to="/forgot-password" className="text-dark">&nbsp;Reset It</Link>
                  </div>
                </div>
                <div className="col-12 d-grid"><button className="btn btn-primary text-bg-dark" onClick={loginUser} type="button">Sign In</button></div>
                <div>
                  Don’t have an account?
                  <Link to="/register" className="text-dark" >&nbsp;Sign Up</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
