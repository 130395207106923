import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CarousalComponent from "../CarousalComponent/CarousalComponent";
import { homePageApi } from "../api/index";
import "./home.css";
import { ProductCard } from "../product/list/product-card.js";
import { useSelector } from "react-redux";

const HomePage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const state = useSelector((state) => state?.userLogin);

  useEffect(() => {
    const loadHomeData = () => {
      homePageApi().then((response) => {
        setCategories(response?.categories);
        setProducts(response?.products);
      });
    };
    loadHomeData();
  }, []);

  return (
    <main>
      <CarousalComponent />
      <section>
        <div className="container">
          <div className="row">
            {categories?.map((category,index) => (
              <div className="col-12 col-md-6 mb-3 mb-lg-0 mt-10" key={index}>
                <div>
                  <div
                    className="py-10 px-8 rounded"
                    style={{
                      background:
                        "url(assets/images/banner/grocery-banner.jpg) no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div>
                      <h3 className="fw-bold mb-1">{category?.name}</h3>
                      <p className="mb-4">
                        Get Upto
                        <span className="fw-bold">30%</span>
                        Off
                      </p>
                      <Link
                        to={`/products/${category?.slug}`}
                        className="btn btn-dark"
                      >
                        Shop Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Popular Products Start*/}
      <section className="my-lg-14 my-8">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-6">
              <h3 className="mb-0">Popular Products</h3>
            </div>
          </div>
          <div className="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-3">
            {products?.map((product, index) => (
              <ProductCard product={product} key={index}/>
            ))}
          </div>
         { products?.length > 0 && <div className="p-5 ps-0">
            <Link to="/products" className="text-#0aad0a text-dark">
              Explore more
            </Link>
          </div> }
        </div>
      </section>
      {/* Popular Products End*/}
      {/* Recent viewed products */}
      <section className="my-lg-14 my-8">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-6">
              <h3 className="mb-0">Recent Viewed Products</h3>
            </div>
          </div>
          <div className="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-3">
            {products?.map((product, index) => (
              <ProductCard product={product} key={index}/>
            ))}
          </div>
        </div>
      </section>
      {/* Recent viewed products End */}
      <section className="my-lg-14 my-8">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="mb-8 mb-xl-0">
                <div className="mb-6">
                  <img src="assets/images/icons/clock.svg" alt="" />
                </div>
                <h3 className="h5 mb-3">10 minute grocery now</h3>
                <p>
                  Get your order delivered to your doorstep at the earliest from
                  FreshCart pickup stores near you.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-8 mb-xl-0">
                <div className="mb-6">
                  <img src="assets/images/icons/gift.svg" alt="" />
                </div>
                <h3 className="h5 mb-3">Best Prices &amp; Offers</h3>
                <p>
                  Cheaper prices than your local supermarket, great cashback
                  offers to top it off. Get best pricess &amp; offers.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-8 mb-xl-0">
                <div className="mb-6">
                  <img src="assets/images/icons/package.svg" alt="" />
                </div>
                <h3 className="h5 mb-3">Wide Assortment</h3>
                <p>
                  Choose from 5000+ products across food, personal care,
                  household, bakery, veg and non-veg &amp; other categories.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-8 mb-xl-0">
                <div className="mb-6">
                  <img src="assets/images/icons/refresh-cw.svg" alt="" />
                </div>
                <h3 className="h5 mb-3">Easy Returns</h3>
                <p>
                  Not satisfied with a product? Return it at the doorstep &amp;
                  get a refund within hours. No questions asked
                  <a href="/privacypolicy" className="text-dark"> policy</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default HomePage;
