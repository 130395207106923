import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../App.css";
import MultiCarousal from "../../CarousalComponent/MultiCarousal";
import "../../CarousalComponent/carousalComponent.css";
import { AddRemoveWishListApi, addEditReviews, addToCart, getProductDetail, loadReviews } from "../../api/index.js";
import { initialWishListCheck } from "../../reducers/WishListReducer.js";
import { initialCartListCheck } from "../../reducers/cartListReducer.js";
import "./productDetail.css";
import { format } from "date-fns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';


const ProductDetail = () => {
  const productWishList = useSelector((state) => state?.productWishList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state?.userLogin);
  const params = useParams();
  const productSlug = params?.["id"];
  const [product, setProduct] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [productImage, setProductImages] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [productCount, setProductCount] = useState(1);
  const [reviews, setReview] = useState([]);
  const [isLoadMoreButtonHide, setIsLoadMoreButtonHide] = useState(false);
  const [reviewInputs, setReviewInputs] = useState({
    title: '',
    description: '',
    rating: ''
  });
  const [productInfos, setProductInfos] = useState([]);
  const [sizeCharts, setSizeCharts] = useState({});

  let imgId = 1;

  const ChangeHandler = (event) => {
    imgId = event.target.accessKey;
    event.preventDefault();
    slideImage();
  };

  useEffect(() => {
    if (!productSlug) return;
    getProductDetail(productSlug).then((data) => {
      setReview([])
      setIsLoadMoreButtonHide(false)
      setProduct(data?.product);
      setProductVariants(data?.productVariant);
      setProductImages(data?.productImages);
      setRelatedProducts(data?.relatedProducts);
      setSelectedVariant(data?.productVariant?.[0]);
      setProductInfos(data?.productInfos);
      setSizeCharts(data?.sizeChart);
    });
  }, [productSlug]);

  function slideImage() {
    const displayWidth = document.querySelector(
      ".img-showcase img:first-child"
    ).clientWidth;
    document.querySelector(".img-showcase").style.transform = `translateX(${-(imgId - 1) * displayWidth
      }px)`;
  }
  window.addEventListener("resize", slideImage);

  const AddToCartButton = () => {

    if (userLogin?.loggedIn) {
      if (product?.id && selectedVariant?.id && productCount) {
        let params = {
          product_id: product?.id,
          product_variant_id: selectedVariant?.id,
          quantity: productCount
        }
        addToCart(params).then((res) => {

          if (res?.status === 'success') {
            dispatch(initialCartListCheck());
            toast.success(`${res?.message}`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
          } else {
            toast.error('Something went wrong!', {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
          }

        }).catch((err) => console.log(err));
      }
    } else {
      navigate("/login", { state: { redirectTo: "/cart" } });
    }
  };

  const AddRemoveWishListButton = () => {
    if (userLogin?.loggedIn) {
      if (product?.id) {
        let params = {
          product_id: product?.id,
        }
        AddRemoveWishListApi(params).then((res) => {
          if (res?.status === "success" && res?.message === "Product Added to Wishlist Successfully") {
            dispatch(initialWishListCheck());
            toast.success(`${res?.message}`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
          } else {
            dispatch(initialWishListCheck());
            toast.error(`${res?.message}`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
            });
          }
        }).catch((err) => console.log(err));
      }
    } else {
      // navigate("/login", { state: { redirectTo: "/cart" }});
    }
  }

  const reviewDetails = (event) => {
    setReviewInputs({ ...reviewInputs, [event.target.name]: event.target.value });
  }

  useEffect(() => {
    if (!product) return
    loadReviews(product?.id, reviews?.length, 10).then((res) => {
      if (!res?.reviews?.length) {
        setIsLoadMoreButtonHide(true)
      }
      setReview(res?.reviews)
    });
  }, [product]);


  const loadMoreReview = () => {
    loadReviews(product?.id, reviews?.length, 10).then((res) => {
      if (!res?.reviews?.length) {
        setIsLoadMoreButtonHide(true)
      }
      setReview(prev => [...prev, ...res?.reviews])
    });
  }

  const clearStarRating = () => {
    let starComponent = document.querySelectorAll('input[name=rating]');
    for (let i = 0; i < starComponent.length; i++) {
      starComponent[i].checked = false;
    }
  }

  const submitReview = () => {
    if (reviewInputs.description !== '' && reviewInputs.title !== '' && reviewInputs.rating !== '') {
      const params = {
        product_id: product?.id,
        ...reviewInputs
      }
      addEditReviews(params).then((res) => {
        if ((res?.code === '200')) {
          loadReviews(product?.id, reviews?.length, 10).then((res) => {
            if (!res?.reviews?.length) {
              setIsLoadMoreButtonHide(true);
            }
            setReview(res?.reviews);
            setReviewInputs({
              title: '',
              description: '',
              rating: ''
            });
            clearStarRating();
          });
        } else {
          alert(res?.message);
          setReviewInputs({
            title: '',
            description: '',
            rating: ''
          });
          clearStarRating();
        }
      });
    } else {
      alert('Please fill the review inputs and then submit');
    }
  }

  const getRating = (event) => {
    setReviewInputs({ rating: event.target.value });
  }

  const subProduct = () => setProductCount(prev => prev - 1)
  const addProduct = () => setProductCount(prev => prev + 1);

  return (
    <>
      <Helmet>
        <title>{product?.title || ""} | Product | zebroz</title>
        <meta name='description' content={product?.title} />
      </Helmet>
      <div className="mt-4">
        <div className="container">
          {/* row */}
          <div className="row">
            {/* col */}
            <div className="col-12">
              {/* breadcrumb */}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-dark">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/products`} className="text-dark">Shop</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link className="text-dark" to={`/products/${product?.category?.slug}`}>{product?.category?.name}</Link>
                  </li>
                  <li className="breadcrumb-item active text-dark" aria-current="page">
                    {product?.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-8">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="product-imgs">
                <div className="img-display">
                  <div className="img-showcase">
                    {productImage?.map((res, i) =>
                      <img
                        src={res.image}
                        alt="shoe image"
                        className="img-details"
                      // style={{  height: 471 }}
                      />
                    )}

                    {/* <img
                      src="https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_2.jpg"
                      alt="shoe image"
                      className="img-details"
                    />
                    <img
                      src="https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_3.jpg"
                      alt="shoe image"
                      className="img-details"
                    />
                    <img
                      src="https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_4.jpg"
                      alt="shoe image"
                      className="img-details"
                    /> */}
                  </div>
                </div>
                <div className="img-select">
                  {productImage?.map((res, i) =>

                    <div className="img-item" onClick={ChangeHandler}>
                      <a href="#" data-id="1" key={i}>
                        <img
                          src={res.image}
                          alt="shoe image"
                          key={i}
                          accessKey={i + 1}
                          className="img-details"
                        />
                      </a>
                    </div>
                  )}

                  {/* <div className="img-item" onClick={ChangeHandler}>
                    <a href="#" data-id="2" key={2}>
                      <img
                        src="https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_2.jpg"
                        alt="shoe image"
                        key={2}
                        accessKey="2"
                        className="img-details"
                      />
                    </a>
                  </div>
                  <div className="img-item" onClick={ChangeHandler}>
                    <a href="#" data-id="3" key={3}>
                      <img
                        src="https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_3.jpg"
                        alt="shoe image"
                        key={3}
                        accessKey="3"
                        className="img-details"
                      />
                    </a>
                  </div>
                  <div className="img-item" onClick={ChangeHandler}>
                    <a href="#" data-id="4" key={4}>
                      <img
                        src="https://fadzrinmadu.github.io/hosted-assets/product-detail-page-design-with-image-slider-html-css-and-javascript/shoe_4.jpg"
                        alt="shoe image"
                        key={4}
                        accessKey="4"
                        className="img-details"
                      />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ps-lg-10 mt-6 mt-md-0">
                {/* content */}
                <Link to={`/products/${product?.category?.slug}`} className="mb-4 d-block text-dark">
                  {product?.category?.name}
                </Link>
                {/* heading */}
                <h1 className="mb-1">{product?.title}</h1>
                <div className="mb-4">
                  {/* rating */}
                  {/* rating */}
                  <small className="text-warning">
                    <i className={`bi bi-star${product?.rating >= 1 ? "-fill" : product?.rating >= 0.5 ? "-half" : ""}`} />
                    <i className={`bi bi-star${product?.rating >= 2 ? "-fill" : product?.rating >= 1.5 ? "-half" : ""}`} />
                    <i className={`bi bi-star${product?.rating >= 3 ? "-fill" : product?.rating >= 2.5 ? "-half" : ""}`} />
                    <i className={`bi bi-star${product?.rating >= 4 ? "-fill" : product?.rating >= 3.5 ? "-half" : ""}`} />
                    <i className={`bi bi-star${product?.rating >= 5 ? "-fill" : product?.rating >= 4.5 ? "-half" : ""}`} />
                  </small>
                  <span className="ms-2">
                    ({product?.review} reviews)
                  </span>
                </div>
                <div className="fs-4">
                  {/* price */}
                  <span className="fw-bold text-dark">
                    ₹{selectedVariant?.price}{" "}
                  </span>
                  {selectedVariant?.offer ? (
                    <>
                      <span className="text-decoration-line-through text-muted">
                        ₹{selectedVariant?.original_price}
                      </span>
                      <span>
                        <small className="fs-6 ms-2 text-danger">{selectedVariant?.offer} Off</small>
                      </span>
                    </>
                  ) : null}
                </div>
                {/* hr */}
                <hr className="my-6" />

                <div class="modal fade" id="sizeChartModal" tabindex="-1" aria-labelledby="sizeChartLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body p-6">
                        <div class="d-flex justify-content-between mb-5">
                          <div>
                            <h5 class="mb-1" id="sizeChartLabel">{sizeCharts.title}</h5>
                            <p class="small mb-0">All sizes are of Garments only !</p>
                          </div>
                          <div>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                        </div>
                        <div className="col-12 table-responsive-sm table-responsive-md table-responsive-lg">
                          <table className="table table-sm table-md table-striped">
                            <tbody>
                              {[...(sizeCharts?.table || [])]?.map((table, i) => {
                                if (i === 0) {
                                  return (
                                    <tr key={`welcome-${i}`}>
                                      {table?.map((tableData, i) => <th>{tableData}</th>)}
                                    </tr>
                                  )
                                } else {
                                  return (
                                    <tr key={`welcome-${i}`}>
                                      {table?.map((tableData, i) => <td>{tableData}</td>)}
                                    </tr>
                                  )
                                }
                              }
                              )}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-end pb-5">
                  <span className="fs-5 fw-bold">Please select a {productVariants?.[0]?.attribute_value?.attribute?.title}</span>
                  <button className="bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#sizeChartModal">Size Chart</button>
                </div>
                <div className="mb-5 d-flex column-gap-1">
                  {/* buttons */}
                  {productVariants?.map((productVariant, i) => (
                    <button
                      type="button"
                      onClick={() => setSelectedVariant(productVariant)}
                      className={` btn btn-outline-secondary me-2 mb-2 ${productVariant?.id === selectedVariant?.id ? 'active text-bg-dark' : ''}`}
                    >
                      {productVariant?.attribute_value?.title}
                    </button>
                  ))}
                </div>
                <div>
                  {/* input */}
                  <div className="input-group input-spinner">
                    <input
                      type="button"
                      defaultValue="-"
                      className="button-minus btn btn-sm"
                      data-field="quantity"
                      onClick={subProduct}
                      disabled={productCount <= 1 ? true : false}
                    />
                    <input
                      type="number"
                      step={1}
                      max={10}
                      value={productCount}
                      defaultValue={productCount}
                      name="quantity"
                      className="quantity-field form-control-sm form-input"
                    />
                    <input
                      type="button"
                      defaultValue="+"
                      className="button-plus btn btn-sm"
                      data-field="quantity"
                      onClick={addProduct}
                      disabled={productCount > 9 ? true : false}
                    />
                  </div>
                </div>
                <div className="mt-3 row justify-content-start g-2 align-items-center">
                  <div className="col-xxl-4 col-lg-4 col-md-5 col-5 d-grid">
                    {/* button */}
                    {/* btn */}
                    <button
                      type="button"
                      className="btn btn-primary text-bg-dark"
                      disabled={!selectedVariant?.id}
                      onClick={AddToCartButton}
                    >
                      <i className="feather-icon icon-shopping-bag me-2" />
                      Add to cart
                    </button>
                  </div>
                  <div className="col-md-4 col-4">
                    {/* btn */}
                    <button
                      onClick={AddRemoveWishListButton}
                      className="btn btn-light"
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      aria-label="Wishlist"
                    >
                      <i className={`bi bi-heart${productWishList?.productsId?.includes(product?.id)
                        ? "-fill"
                        : ""
                        }`}
                      />
                    </button>
                  </div>
                </div>
                {/* hr */}
                <hr className="my-6" />
                <div>
                  {/* table */}
                  <table className="table table-borderless mb-0">
                    <tbody>
                      {/* <tr>
                        <td>Product Code:</td>
                        <td>FBB00255</td>
                      </tr> */}
                      {productInfos ? productInfos?.map((values) => <tr>
                        <td style={{ color: "#666875" }}>{values.title}</td>
                        <td style={{ color: "#000" }}>{values.value}</td>
                      </tr>) : null}
                      {/* <tr>
                        <td style={{ color: "#666875" }}>Type:</td>
                        <td style={{ color: "#000" }}>Round Neck</td>
                      </tr>
                      <tr>
                        <td style={{ color: "#666875" }}>Fabric:</td>
                        <td style={{ color: "#000" }}>220 GSM 100% Cotton</td>
                      </tr>
                      <tr>
                        <td style={{ color: "#666875" }}>Pattern:</td>
                        <td style={{ color: "#000" }}>Solid</td>
                      </tr>
                      <tr>
                        <td style={{ color: "#666875" }}>Sleeve:</td>
                        <td style={{ color: "#000" }}>Regular Sleeve</td>
                      </tr>
                      <tr>
                        <td style={{ color: "#666875" }}>Length:</td>
                        <td style={{ color: "#000" }}>Regular</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                <div className="mt-8">
                  {/* dropdown */}
                  <div className="dropdown">
                    <a
                      className="btn btn-outline-secondary dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Share
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href={`whatsapp://send?text=Product Name : *${product?.title}*\n Link : https://zebroz.in${`/products/${product?.category?.slug}`}`} target="_blank">
                          <i className="bi bi-whatsapp me-2" />
                          Whatsapp
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="https://www.instagram.com/zebroz.in/" target="_blank">
                          <i className="bi bi-facebook me-2" />
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="https://www.instagram.com/zebroz.in/" target="_blank">
                          <i className="bi bi-instagram me-2" />
                          Instagram
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-8">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul
                className="nav nav-pills nav-lb-tab"
                id="myTab"
                role="tablist"
              >
                {/* nav item */}
                <li className="nav-item" role="presentation">
                  {/* btn */}
                  <button
                    className="nav-link active text-dark"
                    id="product-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#product-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="product-tab-pane"
                    aria-selected="true"
                  >
                    Product Details
                  </button>
                </li>
                {/* nav item */}
                <li className="nav-item" role="presentation">
                  {/* btn */}
                  <button
                    className="nav-link text-dark"
                    id="reviews-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#reviews-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="reviews-tab-pane"
                    aria-selected="false"
                  >
                    Reviews
                  </button>
                </li>
              </ul>
              {/* tab content */}
              <div className="tab-content" id="myTabContent">
                {/* tab pane */}
                <div
                  className="tab-pane fade show active"
                  id="product-tab-pane"
                  role="tabpanel"
                  aria-labelledby="product-tab"
                  tabIndex={0}
                >
                  <div className="my-8">
                    <div className="mb-5" style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: product?.description }}></div>
                  </div>
                </div>
                {/* tab pane */}
                <div
                  className="tab-pane fade"
                  id="reviews-tab-pane"
                  role="tabpanel"
                  aria-labelledby="reviews-tab"
                  tabIndex={0}
                >
                  <div className="my-8">
                    {/* row */}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="me-lg-12 mb-6 mb-md-0">
                          <div className="mb-5">
                            {/* title */}
                            <h4 className="mb-3">Customer reviews</h4>
                            <span>
                              {/* rating */}
                              <small className="text-warning">
                                <i className={`bi bi-star${product?.rating >= 1 ? "-fill" : product?.rating >= 0.5 ? "-half" : ""}`} />
                                <i className={`bi bi-star${product?.rating >= 2 ? "-fill" : product?.rating >= 1.5 ? "-half" : ""}`} />
                                <i className={`bi bi-star${product?.rating >= 3 ? "-fill" : product?.rating >= 2.5 ? "-half" : ""}`} />
                                <i className={`bi bi-star${product?.rating >= 4 ? "-fill" : product?.rating >= 3.5 ? "-half" : ""}`} />
                                <i className={`bi bi-star${product?.rating >= 5 ? "-fill" : product?.rating >= 4.5 ? "-half" : ""}`} />
                              </small>
                              <span className="ms-3">{product?.rating} out of 5</span>
                              <small className="ms-3">
                                {product?.review} global ratings
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* col */}
                      <div className="col-md-8">
                        <div className={reviews.length > 0 ? "mb-4" : "mb-0"} >
                          <div className="d-flex justify-content-between align-items-center mb-8">
                            <div>
                              {/* heading */}
                              {reviews.length > 0 ? <h4>Reviews</h4> : <h4>No Reviews yet</h4>}
                            </div>
                            {/* <div>
                              <select className="form-select">
                                <option selected>Top Reviews</option>
                                <option value="Most Recent">Most Recent</option>
                              </select>
                            </div> */}
                          </div>

                          {
                            reviews?.map((res, index) => <>
                              <div className="d-flex border-bottom pb-6 mb-6" key={`review-${index}`}>
                                {/* <img
                                  src="../assets/images/avatar/avatar-10.jpg"
                                  alt=""
                                  className="rounded-end avatar-lg"
                                /> */}
                                <div>
                                  <h6 className="mb-1">{res?.customer.name}</h6>
                                  <p className="small mb-3">
                                    <span className="text-muted">
                                      {format(res?.created_at, 'dd-MM-yyyy')}
                                    </span>
                                    <span className="text-primary ms-3 fw-bold">
                                      Verified
                                    </span>
                                  </p>
                                  <div className="mb-2">
                                    <i className={`bi bi-star${res?.rating >= 1 ? "-fill" : res?.rating >= 0.5 ? "-half" : ""}`} />
                                    <i className={`bi bi-star${res?.rating >= 2 ? "-fill" : res?.rating >= 1.5 ? "-half" : ""}`} />
                                    <i className={`bi bi-star${res?.rating >= 3 ? "-fill" : res?.rating >= 2.5 ? "-half" : ""}`} />
                                    <i className={`bi bi-star${res?.rating >= 4 ? "-fill" : res?.rating >= 3.5 ? "-half" : ""}`} />
                                    <i className={`bi bi-star${res?.rating >= 5 ? "-fill" : res?.rating >= 4.5 ? "-half" : ""}`} />
                                    <span className="ms-3 text-dark fw-bold">
                                      {res?.title}
                                    </span>
                                  </div>
                                  <p>
                                    {res?.description}
                                  </p>
                                  {/* <div>
                                    <div className="border icon-shape icon-lg border-2">
                                      <img
                                        src="../assets/images/products/product-img-1.jpg"
                                        alt=""
                                        className="img-fluid img-details"
                                      />
                                    </div>
                                    <div className="border icon-shape icon-lg border-2 ms-1">
                                      <img
                                        src="../assets/images/products/product-img-2.jpg"
                                        alt=""
                                        className="img-fluid img-details"
                                      />
                                    </div>
                                    <div className="border icon-shape icon-lg border-2 ms-1">
                                      <img
                                        src="../assets/images/products/product-img-3.jpg"
                                        alt=""
                                        className="img-fluid img-details"
                                      />
                                    </div>
                                  </div> */}
                                  {/* <div className="d-flex justify-content-end mt-4">
                                    <a href="#" className="text-muted">
                                      <i className="feather-icon icon-thumbs-up me-1" />
                                      Helpful
                                    </a>
                                    <a href="#" className="text-muted ms-4">
                                      <i className="feather-icon icon-flag me-2" />
                                      Report abuse
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </>)
                          }

                          <div>
                            <span
                              hidden={isLoadMoreButtonHide}
                              onClick={loadMoreReview}
                              className="btn btn-outline-gray-400 text-muted"
                            >
                              Read More Reviews
                            </span>
                          </div>
                        </div>
                        <div>
                          {/* rating */}

                          <div className="pt-4">

                            <h5>Overall rating</h5>

                            <div className="ratingComponent d-flex align-items-center">

                              <div class="rating">
                                <input type="radio" name="rating" value="5" id="5" onClick={getRating} /><label for="5">☆</label>
                                <input type="radio" name="rating" value="4" id="4" onClick={getRating} /><label for="4">☆</label>
                                <input type="radio" name="rating" value="3" id="3" onClick={getRating} /><label for="3">☆</label>
                                <input type="radio" name="rating" value="2" id="2" onClick={getRating} /><label for="2">☆</label>
                                <input type="radio" name="rating" value="1" id="1" onClick={getRating} /><label for="1">☆</label>
                              </div>
                            </div>

                          </div>

                          <div className="py-4">

                            <h5>Add a headline</h5>
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="What’s most important to know"
                              onChange={reviewDetails}
                              value={reviewInputs.title}
                            />
                          </div>
                          {/* <div className="border-bottom py-4 mb-4">
                            <h5>Add a photo or video</h5>
                            <p>
                              Shoppers find images and videos more helpful than
                              text alone.
                            </p>
                            {/* form */}
                          {/* <div
                              id="my-dropzone"
                              className="dropzone mt-4 border-dashed rounded-2 min-h-0"
                            /> */}
                          {/* </div> */}
                          <div className="py-4">
                            <h5>Add a written review</h5>
                            <textarea
                              className="form-control"
                              rows={3}
                              placeholder="What did you like or dislike? What did you use this product for?"
                              name="description"
                              defaultValue={""}
                              onChange={reviewDetails}
                              value={reviewInputs.description}
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <a href="#" className="btn btn-primary text-bg-dark" onClick={submitReview}>
                              Submit Review
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* tab pane */}
                <div
                  className="tab-pane fade"
                  id="sellerInfo-tab-pane"
                  role="tabpanel"
                  aria-labelledby="sellerInfo-tab"
                  tabIndex={0}
                >
                  ...
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section */}
      <section className="my-lg-4">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-12">
              {/* heading */}
              <h3>Related Items</h3>
            </div>
          </div>
          {/* row */}
          {/* <div className="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-2 mt-2"> */}
          <MultiCarousal relatedProducts={relatedProducts} />
          {/* </div> */}
        </div>
      </section>

      <ToastContainer />

    </>
  );
};

export default ProductDetail;
