export default function HelpCenter() {
    return (
        <div className="container">
            <h3 className="text-center pt-4">Help Center</h3>
            {/* <p className="ps-10 pe-10">
                To submit return/ exchange requests - Click here
            </p> */}
            {/* <p className="ps-10 pe-10">
                For any other queries and Quicker Help, WhatsApp us - Click here
            </p> */}
            <p className="ps-10 pe-10">
                Please WhatsApp us ‘Hi Zebroz on +91 6383397445 and we will get back to you within 24 hours
            </p>
            {/* <p className="ps-10 pe-10">
            </p> */}
            <p className="ps-10 pe-10">
                Important Note:<br />
                <br />
                Dear Customer,<br />

                In order to serve you faster & efficiently, the official support of Zebroz has been shifted to WhatsApp only on the number - +91 6383397445
                <br />
                Please note, we DO NOT have ANY OTHER NUMBER for Call / WhatsApp as official support
                <br />
                Please beware, if you encounter any other contact number / WhatsApp / email online, it might be a spam
                <br />
                Zebroz.in will never call you for password / OTP which may lead to financial fraud
                <br />
                For any help, feel free to reach out to us - kkigoapparels@gmail.com
            </p>
        </div>
    )
}