import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoginCheckApi } from "../api/index.js";

const initialState = {
  isPageLoaded: false,
  loggedIn: false,
  currentUser: null,
};

const userLogin = createSlice({
  name: "userLogin",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isPageLoaded = true;
      state.loggedIn = true;
      state.currentUser = action?.payload;
    },
    logout: (state, action) => {
      state.isPageLoaded = true;
      state.loggedIn = false;
      state.currentUser = null;
    },
  },
});

export const { login, logout } = userLogin.actions;
export default userLogin.reducer;

// export const userLoginReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "LOGIN":
//       return {
//         ...initialState,
//         authChecked: true,
//         isPageLoaded: true,
//         loggedIn: true,
//         currentUser: action.payload,
//       };
//     case "LOGOUT":
//       return {
//         ...initialState,
//         isPageLoaded: true,
//         loggedIn: false,
//         currentUser: null,
//       };
//     default:
//       return state;
//   }
// };

export const initialSessionCheck = () => async (dispatch) => {
  getLoginInfo(dispatch);
};

const getLoginInfo = async (dispatch) => {
  LoginCheckApi()
    .then((data) => {
      if (data.status === "success") {
        dispatch(login(data?.data?.customer));
      } else {
        dispatch(logout());
      }
    })
    .catch((data) => {
      dispatch(logout());
    });
};
