import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = (props) => {
  const location = useLocation();
  const userLogin = useSelector((state) => state?.userLogin);

  return (
    <>
      {userLogin?.loggedIn ? (
        props.children
      ) : (
        <Navigate
          to="/login"
          replace
          state={{ redirectTo: location.pathname }}
        />
      )}
    </>
  );
};

export default ProtectedRoute;
