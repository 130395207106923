import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { updateCart } from "../api/index";

const CartItemsComponent = (props) => {
  const [productQuantity, setProductQuantity] = useState(0);
  const isStockAvailable = props?.items?.product_variant?.is_allow_purchase === "1" && props?.items?.product_variant?.is_available === "1"

  useEffect(() => {
    setProductQuantity(props?.items?.quantity || 0);
  }, [props?.items?.quantity]);

  const updateCount = (selectedItem) => {
    let params = {
      product_id: selectedItem.product_id,
      product_variant_id: selectedItem.product_variant_id,
      quantity: selectedItem.quantity,
    };
    updateCart(params).then((res) => {
      props.loadData()
    });
  };

  const addCount = () => {
    setProductQuantity((prev) => prev + 1);
    updateCount({ ...props.items, quantity: productQuantity + 1 });
  };

  const subCount = () => {
    setProductQuantity((prev) => prev - 1);
    updateCount({ ...props.items, quantity: productQuantity - 1 });
  };

  const removeItems = (event) => {
    props.removeItems(props?.items?.id);
  };


  return (
    <>
      <li className="list-group-item py-3 ps-0 border-top" key={props?.index} style={{ backgroundColor: isStockAvailable ? "" : "darkgray" }}>
        <div className="row align-items-center">
          <div className="col-6 col-sm-6 col-md-6 col-lg-4">
            <div className="d-flex align-items-center">
              <img
                src={props?.items?.product?.image}
                alt={props?.items?.product?.title}
                className="icon-shape icon-xxl"
              />
              <div className="ms-3">
                <Link
                  to={`/product-detail/${props?.items?.product?.slug}`}
                  className="text-inherit"
                >
                  <h6 className="mb-0">{props?.items?.product?.title}</h6>
                  <span>
                    <small class="text-muted">
                      {props?.items?.product_variant?.attribute_value?.title}
                    </small>
                  </span>
                </Link>
                {/* <span>
                  <small className="text-muted invisible">
                    {props?.items?.quantity}
                  </small>
                </span> */}
              </div>
              
             

            </div>
          </div>

          <div className="col-6 col-sm-6 col-md-6 col-lg-3 mt-2">
                <div className="input-group input-spinner justify-content-center">
                  <input
                    type="button"
                    defaultValue="-"
                    className="button-minus btn btn-sm"
                    onClick={() => subCount()}
                    disabled={productQuantity <= 1 ? true : false}
                  />

                  <input
                    type="number"
                    step={1}
                    max={10}
                    defaultValue={0}
                    value={productQuantity}
                    name="quantity"
                    className="quantity-field form-control-sm form-input"
                  />
                  <input
                    type="button"
                    defaultValue="+"
                    className="button-plus btn btn-sm"
                    onClick={() => addCount()}
                    disabled={productQuantity > 9 ? true : false}
                  />
                </div>
              </div>

              <div className="col-6 mt-2 small pe-sm-11 col-sm-6 col-md-6 col-lg-2 text-center">
                <span className="fw-bold">
                  ₹ {productQuantity * props?.items?.product_variant?.price}
                </span>
              </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-3 text-lg-end text-start text-md-end  align-items-center d-flex mt-2 justify-content-center" style={{ cursor: 'pointer' }}>
                <div className="text-decoration-none text-inherit">
                  <span className="me-1 align-text-bottom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-trash-2 text-success text-dark"
                    >
                      <polyline points="3 6 5 6 21 6" />
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                      <line x1={10} y1={11} x2={10} y2={17} />
                      <line x1={14} y1={11} x2={14} y2={17} />
                    </svg>
                  </span>
                  <span
                    className="text-muted remove-button"
                    onClick={removeItems}
                  >Remove</span>
                </div>
              </div>

          {/* <div className="col-4 text-lg-end text-start text-md-end col-md-2 align-items-center d-flex">
            <div className="text-decoration-none text-inherit">
              <span className="me-1 align-text-bottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={14}
                  height={14}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-trash-2 text-success"
                >
                  <polyline points="3 6 5 6 21 6" />
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                  <line x1={10} y1={11} x2={10} y2={17} />
                  <line x1={14} y1={11} x2={14} y2={17} />
                </svg>
              </span>
              <span
                className="text-muted remove-button"
                onClick={removeItems}
              >Remove</span>
            </div>
          </div> */}
        </div>
      </li>
    </>
  );
};

export default CartItemsComponent;
