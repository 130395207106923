import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import cartCountReducers from "./reducers/CartReducer.js";
import userLoginReducer from "./reducers/LoggedInReducer.js";
import productWishListReducer from "./reducers/WishListReducer.js";
import productCartListReducer from "./reducers/cartListReducer.js";
import reportWebVitals from "./reportWebVitals";

const store = configureStore({
  reducer: {
    countUpdate: cartCountReducers,
    userLogin:userLoginReducer,
    productWishList:productWishListReducer,
    cartList:productCartListReducer
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
