export default function Contactus() {
    return (
        <div className="container">
            <h3 className="text-center pt-4">Contact Us</h3>
            {/* <p className="ps-10 pe-10">
                To submit return/ exchange requests - Click here
            </p> */}
            {/* <p className="ps-10 pe-10">
                For any other queries and Quicker Help, WhatsApp us - Click here
            </p> */}
            {/* <p className="ps-10 pe-10">
                Please WhatsApp us ‘Hi Zebroz on +91 6383397445 and we will get back to you within 24 hours
            </p> */}
            {/* <p className="ps-10 pe-10">
            </p> */}
            <p className="ps-10 pe-10">
                <h4>For queries please contact us below</h4>
                KKIGO Apparels, <br />
                14/1, Nesavalar Colony, <br />
                Tirupur-641602 TamilNadu, India <br />
                Mail:kkgioapparels@gmail.com 
                <br />
                For any help, feel free to reach out to us - kkigoapparels@gmail.com
            </p>
        </div>
    )
}