import React, { useState } from "react";
import { Link } from "react-router-dom";
import { registerUserData } from "../api/index";
import logo from "../assets/zebroz_logo.png";

const intialState = {
  fname: "",
  lname: "",
  email: "",
  mobile: "",
  password: "",
};

export const SignupComponent = () => {
  const [registerData, setRegisterData] = useState(intialState);
  const [checkRegStatus, setRegStatus] = useState({
    regErrorStatus: false,
    regMsg: [],
  });
  const [errorFlag, setErrorFlag] = useState({
    errorStatus: false,
    errorMsg: "",
  });

  const SetInputvalues = (event) => {
    setRegisterData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const validateRegisterData = () => {
    if (
      registerData.fname === "" ||
      registerData.lname === "" ||
      registerData.email === "" ||
      registerData.password === "" ||
      registerData.mobile === ""
    ) {
      setErrorFlag({
        errorStatus: true,
        errorMsg: "Please fill all the fields",
      });
      return false;
    } else {
      setErrorFlag({
        errorStatus: false,
        errorMsg: "Please fill all the fields",
      });
    }

    const params = {
      ...registerData,
      name: `${registerData?.fname} ${registerData?.lname}`
    }

    registerUserData(params).then((res)=> {
      if(res?.code === '200'){
        setRegisterData(intialState);
        setRegStatus({
          regErrorStatus: false,
          regMsg: res?.message,
        });
      }else{
        setRegStatus({
          regErrorStatus: true,
          regMsg: res?.response?.data?.error,
        });
      }
    });

    // axios
    //   .post(`register`, {
    //     ...registerData,
    //     name: `${registerData?.fname} ${registerData?.lname}`,
    //   })
    //   .then((res) => {
    //     if (res?.data?.status === "success") {
    //       setRegisterData(intialState)
    //       setRegStatus({
    //         regErrorStatus: false,
    //         regMsg: "User Registered Successfully",
    //       });
    //     } else {
    //       setRegStatus({
    //         regErrorStatus: true,
    //         regMsg: res?.data,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     setRegStatus({
    //       regErrorStatus: true,
    //       regMsg: err?.response?.data?.error,
    //     });
    //   });
  };

  return (
    <section className="my-lg-14 my-8">

      <div className="container">
        <div className="row justify-content-center align-items-center">
          {/* <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
            <img
              src={logo}
              alt="logo"
              className="img-fluid"
            />
          </div> */}
          <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
            <div className="mb-lg-3 mb-5">
              <h1 className="mb-1 h2 fw-bold">Get Start Shopping</h1>
              <p className="mb-0">Welcome to Zebroz! Enter your email to get started.</p>
            </div>
            <form
              className={
                errorFlag.errorStatus ? "was-validated" : "needs-validation "
              }
            >
              <div className="row g-3">

                {checkRegStatus?.regErrorStatus ? (
                  <div className="col-12">
                    <div class="alert alert-success bg-white border-danger border-none mb-0 text-danger" role="alert">
                      {checkRegStatus?.regMsg.map((msg)=> <>
                        <div>{msg?.message}</div>
                      </>
                      )}
                    </div>
                  </div>
) : !checkRegStatus?.regErrorStatus && checkRegStatus?.regMsg === 'User Registered Successfully' ? <div class="alert alert-success">{checkRegStatus?.regMsg}</div> : null}

                <div className="col">
                  <label
                    htmlFor="formSignupfname"
                    className="form-label visually-hidden"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control border-secondary"
                    id="formSignupfname"
                    value={registerData.fname}
                    name="fname"
                    placeholder="First Name"
                    required
                    onChange={SetInputvalues}
                  />
                  <div className="invalid-feedback">
                    Please enter first name.
                  </div>
                </div>
                <div className="col">
                  <label
                    htmlFor="formSignuplname"
                    className="form-label visually-hidden"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control border-secondary"
                    id="formSignuplname"
                    value={registerData.lname}
                    name="lname"
                    placeholder="Last Name"
                    required
                    onChange={SetInputvalues}
                  />
                  <div className="invalid-feedback">
                    Please enter last name.
                  </div>
                </div>
                <div className="col-12">
                  <label
                    htmlFor="formSignupMobile"
                    className="form-label visually-hidden"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    className="form-control border-secondary"
                    id="formSignupMobile"
                    value={registerData.mobile}
                    name="mobile"
                    placeholder="Mobile Number"
                    required
                    onChange={SetInputvalues}
                  />
                  <div className="invalid-feedback">
                    Please enter Mobile Number.
                  </div>
                </div>
                <div className="col-12">
                  <label
                    htmlFor="formSignupEmail"
                    className="form-label visually-hidden"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control border-secondary"
                    id="formSignupEmail"
                    placeholder="Email"
                    value={registerData.email}
                    name="email"
                    required
                    onChange={SetInputvalues}
                  />
                  <div className="invalid-feedback">Please enter email.</div>
                </div>
                <div className="col-12">
                  <div className="password-field position-relative">
                    <label
                      htmlFor="formSignupPassword"
                      className="form-label visually-hidden"
                    >
                      Password
                    </label>
                    <div className="password-field position-relative">
                      <input
                        type="password"
                        className="form-control fakePassword border-secondary"
                        id="formSignupPassword"
                        value={registerData.password}
                        name="password"
                        placeholder="*****"
                        required
                        onChange={SetInputvalues}
                      />
                      <span>
                        <i className="bi bi-eye-slash passwordToggler" />
                      </span>
                      <div className="invalid-feedback">
                        Please enter password.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-grid">
                  <button
                    type="button"
                    className="btn btn-primary text-bg-dark"
                    onClick={validateRegisterData}
                  // disabled={checkRegStatus.regStatus ? true : false}
                  >
                    Register
                  </button>
                </div>

                {!checkRegStatus.regErrorStatus && (
                  <div className="col-12">
                    <div class="alert alert-success" role="alert">
                      If you have already an account Click{" "}
                      <Link class="alert-link" to="/login">Here</Link>&nbsp;
                      to login
                    </div>
                  </div>
                )}

                <p>
                  <small>
                    By continuing, you agree to our
                    <Link class="alert-link" to="/termsofservice"> Terms of Service </Link>
                    &amp;
                    <Link class="alert-link" to="/privacypolicy"> Privacy Policy</Link>
                  </small>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
