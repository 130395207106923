import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./multiCarousal.css";
import { ProductCard } from "../product/list/product-card.js";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 1
  }
};

const MultiCarousal = ({
  relatedProducts
}) => {
  return (
    <div className="mt-5 mb-5">
      <Carousel
        className="multCarousal"
        responsive={responsive}
        autoPlay={true}
        showDots={false}
        infinite={true}
        partialVisible={false}
        swipeable={true}
      >
        {relatedProducts?.map((relatedProduct, index) => {
          return (
            <ProductCard product={relatedProduct} />
          );
        })}
      </Carousel>
    </div>
  );
};
export default MultiCarousal;