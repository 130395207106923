import { useEffect, useState } from "react";

export const Pagination = ({
    setCurrentPage,
    currentPage,
    totalContentSize,
    pageSize
}) => {
    const [isShowDotedPages, setIsShowDotedPages] = useState(false)
    const [lastPageNumber, setLastPageNumber] = useState(null)
    const [pagesList, setPagesList] = useState([])

    const onPrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const onNext = () => {
        if (currentPage < totalContentSize / pageSize) {
            setCurrentPage(currentPage + 1)
        }
    }

    const onButtonClick = (page) => {
        setCurrentPage(page)
    }

    useEffect(() => {
        const totalPageSize = Math.ceil(totalContentSize / pageSize)
        if (totalPageSize) {
            let pages = []
            /*Show 4 page */
            if (currentPage <= totalPageSize && currentPage + 4 > totalPageSize) {
                /*Show before 3 pages */
                if ((currentPage + 4 > totalPageSize) && (totalPageSize - 5 >= 0) || (totalPageSize < 5)) {
                    const FirstPage = totalPageSize - 3 <= 0 ? 1 : totalPageSize - 3
                    for (let i = FirstPage; i <= totalPageSize; i++) {
                        pages.push(i)
                    }
                } else {  /*show after pages till last page */
                    for (let i = currentPage; i <= totalPageSize; i++) {
                        pages.push(i)
                    }
                }
            } else if (currentPage < totalPageSize - 1) { /* show 1st 2 page */
                pages = [currentPage, currentPage + 1]
            }
            setPagesList(pages)
        }

        /*show last page and doted page */
        if (currentPage + 3 < totalPageSize) {
            setIsShowDotedPages(true)
            setLastPageNumber(totalPageSize)
        } else {
            setIsShowDotedPages(false)
            setLastPageNumber(null)
        }
    }, [currentPage, totalContentSize])

    return (
        <nav>
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} onClick={onPrevious}>
                    <span
                        className="page-link mx-1"
                        aria-label="Previous"
                    >
                        <i className="feather-icon icon-chevron-left" />
                    </span>
                </li>
                {
                    pagesList?.map(pageNumber => (
                        <li className={`page-item`} onClick={() => onButtonClick(pageNumber)}>
                            <span
                                className={`page-link mx-1 ${currentPage === pageNumber ? 'active' : ''}`}
                            >
                                {pageNumber}
                            </span>
                        </li>
                    ))
                }

                {
                    isShowDotedPages ?
                        (<li className="page-item">
                            <span className="page-link mx-1">
                                ...
                            </span>
                        </li>) : null
                }
                {
                    lastPageNumber && isShowDotedPages ? (
                        <li className="page-item" onClick={() => onButtonClick(lastPageNumber)}>
                            <span className="page-link mx-1">
                                {lastPageNumber}
                            </span>
                        </li>
                    ) : null
                }
                <li className={`page-item ${currentPage === totalContentSize / pageSize ? 'disabled' : ''}`} onClick={onNext} >
                    <span
                        className="page-link mx-1"
                        aria-label="Next"
                    >
                        <i className="feather-icon icon-chevron-right" />
                    </span>
                </li>
            </ul>
        </nav>
    )
}