


export default function Termsofservice() {
    return (
        <div className="container">
            <h3 className="text-center pt-4">Terms of Service</h3>
            <h4 className="ps-10 pe-10 pb-0">Overview</h4>
            <p className="ps-10 pe-10">
                This website is operated by Zebroz. Throughout the site, the terms “we”, “us” and “our” refer to Zebroz. Zebroz offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
            </p>
            <p className="ps-10 pe-10">
                By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
            </p>
            <p className="ps-10 pe-10">
                Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
            </p>
            <p className="ps-10 pe-10">
                Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
            </p>
            <p className="ps-10 pe-10">
                Prices for our products are subject to change without notice.
                We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
            </p>
            <p className="ps-10 pe-10">
                Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.
                We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.
                We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.
                We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.
            </p>
            <p className="ps-10 pe-10">
                Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.
            </p>
            <p className="ps-10 pe-10">
                These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and constructed in accordance with the laws of India.
            </p>
            <p className="ps-10 pe-10">
                You can review the most current version of the Terms of Service at any time at this page.
                We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
            </p>
            <p className="ps-10 pe-10">
            Questions about the Terms of Service should be sent to us at kkigoapparels@gmail.com.
            </p>
        </div>
    )
}