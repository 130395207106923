import axios from "axios";

export const registerUserData = (params) => {
  return axios
    .post(`register`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
}

export const loginUserData = (params) => {
  return axios
    .post(`login`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
}

export const logOutUser = () => {
  return axios
    .post(`logout`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
}

export const getMenuCategories = () => {
  return axios
    .get(`menu-categories`)
    .then((res) => {
      return res?.data?.categories;
    })
    .catch((err) => {
      return [];
    });
};

export const getProductPageCategories = () => {
  return axios
    .get(`product-page-categories`)
    .then((res) => {
      return res?.data?.categories;
    })
    .catch((err) => {
      return [];
    });
};

export const getProductsList = ({skip,limit,category_slug}) => {
  return axios
    .get(`products-list`,{
      params:{
        skip,
        limit,
        category_slug
      }
    })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return [];
    });
};

export const getProductDetail = (slug) => {
  return axios
    .get(`product/${slug}`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return [];
    });
};

export const getCartDetail = (slug) => {
  return axios
    .get(`cart/list`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const addToCart = async (params) => {
  return axios
    .post(`cart/add`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const updateCart = async (params) => {
  return axios
    .post(`cart/update-quantity`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const removeItem = async (params) => {
  return axios
    .post(`cart/remove`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const addAddress = async (params) => {
  return axios
    .post(`customer-address/add`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const editAddress = async (params) => {
  return axios
    .post(`customer-address/update`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteAddress = async (params) => {
  return axios
    .post(`customer-address/delete`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
};

export const addressList = async () => {
  return axios
    .get(`customer-address/list`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const checkoutGetOrder = async (params) => {
  return axios
    .post(`checkout/pay-now`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const checkoutApi = async (params) => {
  return axios
    .post(`checkout/checkout`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const homePageApi = async () => {
  return axios
    .get(`home-page`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const LoginCheckApi = async () => {
  return axios
    .get(`login-check`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const WishListApi = async () => {
  return axios
    .get(`wishlist/list`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const AddRemoveWishListApi = async (params) => {
  return axios
    .post(`wishlist/add-remove`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};


export const getUserOrders = ({skip,limit}) => {
  return axios
    .get(`customer/orders-list`,{
      params:{
        skip,
        limit,
      }
    })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return [];
    });
};

export const getOrderTrackingInfo = ({orderProductId,billId}) => {
  return axios
    .get(`customer/order-tracking`,{
      params:{
        orderProductId,
        billId,
      }
    })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return [];
    });
};

export const loadReviews = (product_id,skip,limit) => {
  return axios
    .get(`product-review/${product_id}`,{
      params:{
        skip,
        limit,
      }
    })
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return [];
    });
}

export const addEditReviews = async (params) => {
  return axios
    .post(`product-review/user-review`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return null;
    });
};

export const loadProfile = async () => {
  return axios
    .get(`profile/info`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return [];
    });
}

export const updateProfile = async (params) => {
    return axios
      .post(`profile/update-profile`, params)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
}

export const updatePasswordDetails = async (params) => {
  return axios
    .post(`profile/change-password`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
}

export const sendMobileVerification = async (params) => {
  return axios
    .post(`send-mobile-verification`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
}

export const otpVerification = async (params) => {
  return axios
    .post(`verify-mobile`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
}

export const verifyCustEmail = async (params) => {
  return axios
    .post(`verify-customer-email`, params)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => {
      return err;
    });
}