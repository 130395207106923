import { format } from "date-fns";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import orderedImage from "../assets/carousal_assets/3.jpg";
import "./orderTracker.css";
import { getOrderTrackingInfo } from "../api/index.js";

export default function OrderTracker() {

  useEffect(() => {
    loadOrder()
  }, [])

  const loadOrder = async () => {
    let orderList = await getOrderTrackingInfo({
      orderProductId:1,
      billId:1
    });
    console.log(orderList)
  }

  return (
    <>
      {/* <section className="vh-100" style={{ backgroundColor: "#eee" }}>
        <MDBContainer className="py-5 h-100">
          <MDBRow className="justify-content-center align-items-center h-100">
            <MDBCol>
              <MDBCard
                className="card-stepper"
                style={{ borderRadius: "10px" }}
              >
                <MDBCardBody className="p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column">
                      <span className="lead fw-normal">
                        Your order has been delivered
                      </span>
                      <span className="text-muted small">
                        by DHFL on 21 Jan, 2020
                      </span>
                    </div>
                    <div>
                      <MDBBtn outline>Track order details</MDBBtn>
                    </div>
                  </div>

                  <hr className="my-4" />

                  <div className="d-flex flex-row justify-content-between align-items-center align-content-center">
                    <span className="bi-patch-check"></span>
                    <hr className="flex-fill track-line" />
                    <span className="bi-patch-check"></span>
                    <hr className="flex-fill track-line" />
                    <span className="bi-patch-check"></span>
                    <hr className="flex-fill track-line" />
                    <span className="d-flex justify-content-center align-items-center bi-patch-check-fill">
                      <MDBIcon icon="check text-white" />
                    </span>
                  </div>

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column align-items-start">
                      <span>15 Mar</span>
                      <span>Order placed</span>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <span>15 Mar</span>
                      <span>Order Dispatched</span>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <span>15 Mar</span>
                      <span>Out for delivery</span>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      <span>15 Mar</span>
                      <span>Delivered</span>
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section> */}
      <div class="container py-5">
        <div class="row flex-xs-column-reverse flex-sm-column-reverse flex-lg-row-reverse flex-md-row-reverse row-gap-5 for-mob-product">
          <div class="col-md-6 col-lg-6">
            <div id="tracking-pre"></div>
            <div id="tracking">
              <div class="tracking-list rounded-2">
                <div class="tracking-item">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-date"><img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" class="img-responsive" alt="order-placed" /></div>
                  <div class="tracking-content">Order Placed<span>{format(new Date(), 'dd MMM yyyy hh:mm a')}</span></div>
                </div>
                <div class="tracking-item">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-date"><img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" class="img-responsive" alt="order-placed" /></div>
                  <div class="tracking-content">Order Confirmed<span>{format(new Date(), 'dd MMM yyyy hh:mm a')}</span></div>
                </div>
                <div class="tracking-item">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-date"><img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" class="img-responsive" alt="order-placed" /></div>
                  <div class="tracking-content">Packed the product<span>{format(new Date(), 'dd MMM yyyy hh:mm a')}</span></div>
                </div>
                <div class="tracking-item">
                  <div class="tracking-icon status-current blinker">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-date"><img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" class="img-responsive" alt="order-placed" /></div>
                  <div class="tracking-content">Near by Courier facility<span>{format(new Date(), 'dd MMM yyyy hh:mm a')}</span></div>
                </div>

                <div class="tracking-item-pending">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-date"><img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" class="img-responsive" alt="order-placed" /></div>
                  <div class="tracking-content">Out for Delivery<span>{format(new Date(), 'dd MMM yyyy hh:mm a')}</span></div>
                </div>
                <div class="tracking-item-pending">
                  <div class="tracking-icon status-intransit">
                    <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg>
                  </div>
                  <div class="tracking-date"><img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" class="img-responsive" alt="order-placed" /></div>
                  <div class="tracking-content">Delivered<span>{format(new Date(), 'dd MMM yyyy hh:mm a')}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6">
            <div className="card card-product">
              <div className="card-body">
                <div className="text-center position-relative">
                  <Link to={'/'}>
                    <img
                      src={orderedImage}
                      alt={''}
                      className="img-fluid pt-5"
                    />
                  </Link>
                </div>
                <div className="text-small mb-1">
                  <Link
                    to={`/`}
                    className="text-decoration-none text-muted"
                  >
                    <small>Name</small>
                  </Link>
                </div>
                <h2 className="fs-6">
                  <Link
                    to={`/`}
                    className="text-inherit text-decoration-none"
                  >
                    Product Title
                  </Link>
                </h2>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <span className="text-dark">₹399 </span>
                    {/* {
                product?.product_variant?.offer && (
                  <span className="text-decoration-line-through text-muted">
                    ₹499
                  </span>
                )
              } */}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}