import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { checkoutApi, getCartDetail, removeItem } from "../api/index.js";
import CartItemsComponent from "./CartItemsComponent.js";
import { EmptyCart } from "./EmptyCart.js";
import {initialCartListCheck} from '../reducers/cartListReducer.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderGif from "../assets/Spinner-2.gif";

export default function ViewCart() {
  const navigate = useNavigate();
  const cartList = useSelector((state) => state?.cartList);
  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const [cartProducts, setCartProducts] = useState([]);
  const [loaderFlag, setLoaderFlag] = useState(true);

  useEffect(() => {
    loadProducts();
  }, [cartList]);

  const loadProducts = async() => {
    if(cartList?.carts.length <= 0 ){
      setLoaderFlag(true);
    }else{
      setLoaderFlag(false);
      setTotalAmount(cartList?.total_amount);
      setCartProducts(cartList?.carts);
    }
  }

  const loadData = () => {
    dispatch(initialCartListCheck());
    // getCartDetail().then((data) => {
    //   // setTotalAmount(data?.total_amount);
    //   // setCartProducts(data?.cart);
    // });
  };

  const checkoutHandler = () => {
    if(!cartProducts?.length){
      console.log("No Products")
      return;
    }
    checkoutApi().then((res) => {
      if(res?.checkout_id)
      navigate("/checkout", { state: { checkout_id: res?.checkout_id } });
    });
  };

  const itemRemoveHandler = (cart_id) => {
    removeItem({ cart_id: cart_id }).then((res) =>{
      if(res?.status === 'success'){
        loadData();
        toast.success(`${res?.message}`, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
          });
      }else{
        toast.error(`${res?.message}`, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
          });
      }}
    );
  };

  return (
    <div>
      {/* section */}
      <section className="mb-lg-14 mb-8 mt-8">

      { loaderFlag ? <img src={LoaderGif} alt="" style={{ width: 100, height: 100, margin: '0 auto', display: 'flex' }}></img> :

        <div className="container">
          <div className="mb-8">
            <h1 className="mb-1">Your Cart Items</h1>
          </div>
          <div className="row">
            {cartProducts?.length < 1 ? (
              <EmptyCart />
            ) : (
              <div className="col-lg-8 col-md-7">
                <div className="py-3">
                  <div className="alert alert-danger p-2" role="alert">
                    You’ve got FREE delivery. Start
                    <a href="#!" className="alert-link">
                      {" "}
                      checkout now!
                    </a>
                  </div>
                  <ul className="list-group list-group-flush">
                    {cartProducts?.map((res, i) => (
                      <CartItemsComponent
                        items={res}
                        index={i}
                        removeItems={itemRemoveHandler}
                        loadData={loadData}
                      />
                    ))}
                  </ul>
                  <div className="d-flex justify-content-between mt-4">
                    <Link to="/products" className="btn btn-primary text-bg-dark links">
                      Continue Shopping
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 col-lg-4 col-md-5">
              <div className="mb-5 card mt-6">
                <div className="card-body p-6">
                  <h2 className="h5 mb-4">Summary</h2>
                  <div className="card mb-2">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="me-auto">
                          <div>Item Subtotal</div>
                        </div>
                        <span>₹ {totalAmount}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="me-auto">
                          <div>Shipping & Handling / Tax Fee</div>
                        </div>
                        <span>₹0</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="me-auto">
                          <div className="fw-bold">Subtotal</div>
                        </div>
                        <span className="fw-bold">₹ {totalAmount}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="d-grid mb-1 mt-4">
                    <button
                      className="btn btn-primary btn-lg d-flex justify-content-between align-items-center text-bg-dark"
                      disabled={!cartProducts?.length}
                      onClick={checkoutHandler}
                    >
                      Go to Checkout
                      <span className="fw-bold" type>
                        ₹ {totalAmount}
                      </span>
                    </button>
                  </div>
                  <p>
                    <small>
                      By placing your order, you agree to be bound by the
                      Freshcart&nbsp;
                      <Link className="text-dark" to="/termsofservice">Terms of Service&nbsp;</Link>
                      and
                      <Link className="text-dark" to="/privacypolicy">&nbsp;Privacy Policy.</Link>
                    </small>
                  </p>
                  <div className="mt-8">
                    <h2 className="h5 mb-3">Add Promo or Gift Card</h2>
                    <form>
                      <div className="mb-2">
                        <label
                          htmlFor="giftcard"
                          className="form-label sr-only"
                        >
                          Email address
                        </label>
                        <input
                          type="text"
                          disabled
                          className="form-control"
                          id="giftcard"
                          placeholder="Promo or Gift Card"
                        />
                      </div>
                      <div className="d-grid">
                        <button
                          type="submit"
                          disabled
                          className="btn btn-outline-dark mb-1"
                        >
                          Redeem
                        </button>
                      </div>
                      <p className="text-muted mb-0">
                        <small>Terms &amp; Conditions apply</small>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
}
      </section>

      <ToastContainer />

    </div>
  );
}
