import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProductCard = ({ product }) => {
  const productWishList = useSelector((state) => state?.productWishList);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <div className="col" onClick={scrollTop}>
      <div className="card card-product">
        <div className="card-body">
          <div className="text-center position-relative">
            <div className="container d-flex justify-content-between position-absolute top-80 start-0 p-0">
              <span className="badge bg-grey">
                <div>
                  <small className="text-warning">
                    <i className="bi bi-star-fill" />
                  </small>
                  <span className="text-muted small">
                    {product?.rating} ({product?.review})
                  </span>
                </div>
              </span>
              <span className="badge text-bg-dark">14%</span>
            </div>
            <Link to={`/product-detail/${product?.slug}`}>
              <img
                src={product?.image}
                alt={product?.title}
                className="img-fluid pt-5"
              />
            </Link>
          </div>
          <div className="text-small mb-1">
            <Link
              to={`/products/${product?.category?.slug}`}
              className="text-decoration-none text-muted"
            >
              <small>{product?.category?.name}</small>
            </Link>
          </div>
          <h2 className="fs-6">
            <Link
              to={`/product-detail/${product?.slug}`}
              className="text-inherit text-decoration-none"
            >
              {product?.title}
            </Link>
          </h2>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
              <span className="text-dark">₹{product?.product_variant?.price} </span>
              {
                product?.product_variant?.offer && (
                  <span className="text-decoration-line-through text-muted">
                    ₹{product?.product_variant?.original_price}
                  </span>
                )
              }
            </div>

            <span
              className="btn-action"
              style={{ display: 'none' }}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Wishlist"
            >
              <i
                className={`bi bi-heart${productWishList?.productsId?.includes(product?.id)
                  ? "-fill"
                  : ""
                  }`}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
