import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { initialWishListCheck } from "../reducers/WishListReducer.js";
import { AddRemoveWishListApi, addToCart, getProductDetail } from "../api/index.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import wishlist_empty from "../assets/wishlist_empty.png";

export default function Wishlist() {
  const dispatch = useDispatch();
  const productWishList = useSelector((state) => state?.productWishList);
  const userLogin = useSelector((state) => state?.userLogin);


  const AddRemoveWishListButton = (product) => {
    if (userLogin?.loggedIn) {
      if (product?.id) {
        let params = {
          product_id: product?.id,
        }
        AddRemoveWishListApi(params).then((res) => {
          if(res?.status === 'success'){
            dispatch(initialWishListCheck());
            toast.success(`${res?.message}`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
              });
          }else{
            toast.success(`${res?.message}`, {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
              });
          }
        }).catch((err) => console.log(err));
      }
    } else {
      // navigate("/login", { state: { redirectTo: "/cart" }});
    }
  }

  return (
    <>
      <div className="mt-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-dark">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/products" className="text-dark">Shop</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Wishlist
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      { productWishList?.productsId?.length > 0 ? 
      
 <section className="mt-8 mb-14">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-4">
                <h1 className="mb-1">My Wishlist</h1>
                <p>{productWishList?.productsId?.length || 0} products</p>
              </div>
              <div>
                <div className="table-responsive">
                  <table className="table text-nowrap table-with-checkbox">
                    <thead className="table-light">
                      <tr>
                        {/* <th>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue
                              id="checkboxAll"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="checkboxAll"
                            />
                          </div>
                        </th> */}
                        <th></th>
                        <th>Product</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productWishList?.products?.map((res, i) => (
                        <tr> 
                          {/* <td className="align-middle">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue
                                id="wishCheckbox"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="wishCheckbox"
                              />
                            </div>
                          </td> */}
                           <td className="align-middle">
                            <Link to={`/product-detail/${res?.product?.slug}`}>
                              <img
                                src={res?.product?.image}
                                className="icon-shape icon-xxl"
                                alt=""
                              />
                            </Link>
                          </td>
                          <td className="align-middle">
                            <div>
                              <h5 className="fs-6 mb-0">
                                <Link
                                  to={`/product-detail/${res?.product?.slug}`}
                                  className="text-inherit"
                                >
                                  {res?.product?.title}
                                </Link>
                              </h5>
                            </div>
                          </td> 
                          {/* <td className="align-middle">
                            <span
                              className={`badge ${
                                res?.productStock ? "bg-success" : "bg-danger"
                              }`}
                            >
                              {res?.productStock ? "In Stock" : "Out of Stock"}
                            </span>
                          </td> */}
                          {/* <td className="align-middle">
                            <div className="btn btn-primary btn-sm">
                              {res?.productStock ? "Add to Cart" : "Contact us"}
                            </div>
                          </td> */}
                         <td className="align-middle">
                            <span
                              className="text-muted"
                              style={{cursor:"pointer"}}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                              onClick={()=>AddRemoveWishListButton(res?.product)}
                            >
                              <i className="feather-icon icon-trash-2" />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  
      : 
        <div className="text-center p-4">
          <img alt="" src={wishlist_empty} className="w-20 h-20"></img>
          <h3 className="pt-7">Your wishlists are Empty Now..!</h3>
        </div>
       }

      
      <ToastContainer />
    </>
  );
}
