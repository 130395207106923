import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../App.css";
import { getMenuCategories } from "../api/index.js";
import zebroz_logo from "../assets/zebroz_logo.png";
import zebroz_name from "../assets/zebroz_name.png";

const NavBar = () => {
  const productWishList = useSelector((state) => state?.productWishList);
  const userLogin = useSelector((state) => state?.userLogin);
  const cartList = useSelector((state) => state?.cartList);
  const [categories, setCategories] = React.useState([]);

  useEffect(() => {
    getMenuCategories().then((data) => {
      setCategories(data);
    });
  }, []);


  return (
    <div className="border-bottom">
      <div className="py-3">
        <div className="container">
          <div className="row w-100 align-items-center gx-lg-2 gx-0 justifyContent">
            <div className="col-xxl-2 col-lg-3 col-md-6 col-5">
              <Link className="navbar-brand d-none d-lg-block" to="/">
                <div className="d-flex align-items-center">
                <img
                  src={zebroz_logo}
                  alt="Zebroz"
                  className="w-20 h-20"
                />
                <img
                  src={zebroz_name}
                  alt="Zebroz"
                  className="w-20 h-20 position-absolute"
                />
                </div>
              </Link>
              <div className="d-flex justify-content-between w-100 d-lg-none">
                <Link className="navbar-brand d-flex align-items-center" to="/">
                <img
                  src={zebroz_logo}
                  alt="Zebroz"
                  className="w-25 h-20"
                />
                <img
                  src={zebroz_name}
                  alt="Zebroz"
                  className="w-50 position-absolute ms-n1"
                />
                </Link>
              </div>
            </div>
            <div className="col-xxl-5 col-lg-5 d-none d-lg-block"></div>

            <div className="col-lg-2 col-xxl-2 text-end col-md-6 col-7">
              <div className="list-inline">
                {
                  userLogin?.loggedIn ? (
                    <div className="list-inline-item me-5">
                      <Link to="/wishlist" className="text-muted position-relative" title="wishlist">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-heart"
                        >
                          <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
                        </svg>
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success bg-dark">
                          {productWishList?.productsId?.length}
                          <span className="visually-hidden">unread messages</span>
                        </span>
                      </Link>
                    </div>
                  ) : null
                }
                <div className="list-inline-item me-5">
                  <Link to="/account/order" className="text-muted" title="profile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                  </Link>
                </div>
                <div className="list-inline-item me-5 me-lg-0">
                  <Link
                    className="text-muted position-relative"
                    to="/cart"
                    role="button"
                    title="bag"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-shopping-bag"
                    >
                      <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z" />
                      <line x1={3} y1={6} x2={21} y2={6} />
                      <path d="M16 10a4 4 0 0 1-8 0" />
                    </svg>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                      {cartList?.carts?.length}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </Link>
                </div>

                <div className="list-inline-item d-inline-block d-lg-none">
                  {/* Button */}
                  <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#navbar-default"
                    aria-controls="navbar-default"
                    aria-label="Toggle navigation"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={32}
                      height={32}
                      fill="currentColor"
                      className="bi bi-text-indent-left text-dark"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm.646 2.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8 2.646 6.354a.5.5 0 0 1 0-.708zM7 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        className="navbar navbar-expand-lg navbar-light navbar-default py-0 pb-lg-4"
        aria-label="Offcanvas navbar large"
      >
        <div className="container">
          <div
            className="offcanvas offcanvas-start"
            tabIndex={-1}
            id="navbar-default"
            aria-labelledby="navbar-defaultLabel"
          >
            <div className="offcanvas-header pb-1">
              <Link to="/" className="navbar-brand">
              <div className="d-flex align-items-center">
                <img
                  src={zebroz_logo}
                  alt="Zebroz"
                  className="w-15 h-15"
                  data-bs-dismiss="offcanvas"
                />
                <img
                  src={zebroz_name}
                  alt="Zebroz"
                  className="w-50 position-absolute ms-3"
                  data-bs-dismiss="offcanvas"
                />
                </div>
              </Link>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <div>
                <ul className="navbar-nav align-items-center">
                  <li className="nav-item w-100 w-lg-auto" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link"
                      to="/"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item dropdown w-100 w-lg-auto">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="/products"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Categories
                    </Link>
                    <ul className="dropdown-menu" data-bs-dismiss="offcanvas">
                      {categories?.map((category, index) => {
                        return (
                          <li key={index}>
                            <Link
                              className="dropdown-item"
                              to={`/products/${category.slug}`}
                            >
                              {category.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item w-100 w-lg-auto" data-bs-dismiss="offcanvas">
                    <Link className="nav-link" to="/contact-us">
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item w-100 w-lg-auto" data-bs-dismiss="offcanvas">
                    <Link
                      className="nav-link"
                      to="/helpcenter"
                    >
                      Help
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
