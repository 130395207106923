export default function ProductReturn() {
    return (
        <div className="container">
            <h3 className="text-center pt-4">Product Return</h3>
            <h5 className="ps-10 pe-10 pb-0">Returns & Exchanges</h5>
            <p className="ps-10 pe-10">
                Items purchased from Zebroz are eligible for return/exchange, if returned within 7 days of delivery.
            </p>
            <p className="ps-10 pe-10">
                For Prepaid Orders: Your entire amount will be promptly refunded to your original payment mode, whether it's your bank account or credit card or any other mode of payment within 5-7 business days.
            </p>
            <p className="ps-10 pe-10">
                Cash On Delivery : A refund will be initiated to the bank account within 5-7 business days that is provided by you at the time of raising a return.
                In case of an exchange, you are also allowed to choose a different product. If the value of the replacement product exceeds that of the previously purchased product, you can pay just the difference.
            </p>
            <p className="ps-10 pe-10">
                We accept exchanges free of cost. This means you wont be charged extra to exchange the product(s). It's on us! We want your experience to be hassle-free.
            </p>

            <h5 className="ps-10 pe-10 pb-0">Shipping Return</h5>

            <p className="ps-10 pe-10">
                We offer free shipping across India for all prepaid orders. For COD orders nominal charge is applicable depending on the location.
            </p>

            <p className="ps-10 pe-10">
                For most serviceable pin codes, we try to deliver within 5 days. There could be a possible delay of 2-3 business days in delivery. However, you will be able to track your package using a unique tracking link that we will email/SMS you after your order is sent to our delivery partner.
            </p>

            <p className="ps-10 pe-10">
                We strive to fulfill orders as soon as you place them. In most cases, your order will be expected to be dispatched within 3-4 business days. Our business days are Monday-Saturday.
            </p>

            {/* <p className="ps-10 pe-10">
                You'll receive a tracking number from us in your inbox as soon as it ships! Orders can be tracked in real-time via this link - <a href="/">Track Order</a>
            </p> */}

        </div>
    )
}