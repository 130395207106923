import { createSlice } from "@reduxjs/toolkit";
import { WishListApi } from "../api/index.js";

const initialState = {
  productsId: [],
  products: [],
};

const productWishList = createSlice({
  name: "productWishList",
  initialState,
  reducers: {
    wishListInfo: (state, action) => {
      state.productsId = action?.payload?.productsId;
      state.products = action?.payload?.products;
    },
    wishListInfoError: (state, action) => {
      state.productsId = [];
      state.products = [];
    },
  },
});

export const { wishListInfo, wishListInfoError } = productWishList.actions;
export default productWishList.reducer;

export const initialWishListCheck = () => async (dispatch) => {
  getInitialWishList(dispatch);
};

const getInitialWishList = async (dispatch) => {
  WishListApi()
    .then((data) => {
      if (data.status === "success") {
        dispatch(
          wishListInfo({
            products: data?.products,
            productsId: data?.productsId,
          })
        );
      } else {
        dispatch(wishListInfoError());
      }
    })
    .catch((data) => {
      dispatch(wishListInfoError());
    });
};
