export default function ShippingPolicy() {
    return (
        <div className="container">
            <h3 className="text-center pt-4">Shipping Policy</h3>
            {/* <p className="ps-10 pe-10">
                We offer free shipping across India for all prepaid orders. For COD orders an additional fee is applicable towards "cash handling" by the carrier partner.
            </p> */}
            <p className="ps-10 pe-10">
                We strive to fulfill orders as soon as you place them. In most cases, your order will be expected to be dispatched within 3-5 business days. Our business days are Monday-Saturday.
            </p>
            <p className="ps-10 pe-10">
                For most serviceable pin codes, we try to deliver within 7 days. Currently, our services are likely to be impacted due to the COVID-19 precautionary measures undertaken. There could be a possible delay of 3-4 business days in delivery. However, you will be able to track your package using a unique tracking link that we will email/SMS you after your order is sent to our delivery partner.
            </p>
            <p className="ps-10 pe-10">
                You'll receive a tracking number from us in your inbox as soon as it ships! Orders can be tracked in real-time through courier partner.
            </p>
        </div>
    )
}