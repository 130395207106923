import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function ForgotPassword() {

  const [mailId, setMailId] = useState({
    email : ''
  });
  const [errorFlag, setErrorFlag] = useState(false);

  const getEmailId = (event) => {
    setErrorFlag(false);
    setMailId({ ...mailId, [event.target.id]: event.target.value });
  }

  const validateMailId = () => {
    if(mailId.email !==''){
      console.log('We are Good', mailId);
    }else{
      setErrorFlag(true);
    }
  }

    return(
        <section className="my-lg-14 my-8">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            {/* <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
              <img src="../assets/images/svg-graphics/fp-g.svg" alt="" className="img-fluid" />
            </div> */}
            <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1 d-flex align-items-center">
              <div>
                <div className="mb-lg-9 mb-5">
                  <h1 className="mb-2 h2 fw-bold">Forgot your password?</h1>
                  <p>Please enter the email address associated with your account and We will email you a link to reset your password.</p>
                </div>
                <form className={errorFlag ? "was-validated": "needs-validation "}>
                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="email" className="form-label visually-hidden">Email address</label>
                      <input type="email" className="form-control border-secondary" id="email" placeholder="Email" required onChange={getEmailId}/>
                      <div className="invalid-feedback">Please enter valid mail ID.</div>
                    </div>
                    <div className="col-12 d-grid gap-2">
                      <button type="button" className="btn btn-primary text-bg-dark" onClick={validateMailId}>Reset Password</button>
                      <Link to="/register" className="btn btn-light">&nbsp;Back</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}