import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCartDetail } from "../api";

const initialState = {
  count: 0,
};

export const getCartCountWithNewThunk = createAsyncThunk(
  "cart/list",
  async () => {
    const response = await getCartDetail();
    return response?.cart;
  }
);

const cartCount = createSlice({
  name: "posts",
  initialState,
  reducers: {
    increment: (state, action) => {
      state.count = state.count + 1;
    },
    decrement: (state, action) => {
      state.count = state.count - 1;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCartCountWithNewThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCartCountWithNewThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        let object = action?.payload;
        let newCount = 0;
        if (object?.length === 1) {
          newCount = object?.[0]?.quantity;
        } else {
          newCount = object?.reduce((c, v) => c?.quantity + v?.quantity);
        }
        state.count = newCount;
      })
      .addCase(getCartCountWithNewThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { increment, decrement } = cartCount.actions;
export default cartCount.reducer;

export const getCartCount = async (dispatch, getState) => {
  const res = await getCartDetail().then((response) => response);
  dispatch({ type: "CART_COUNT", payload: res?.cart });
};
