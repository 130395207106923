import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logOutUser } from "../api/index";
import zebroz_logo from "../assets/zebroz_logo.png";
import zebroz_name from "../assets/zebroz_name.png";

import { initialSessionCheck } from "../reducers/LoggedInReducer.js";
import { initialWishListCheck } from "../reducers/WishListReducer.js";
import { initialCartListCheck } from "../reducers/cartListReducer.js";

export const AccountLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state?.userLogin);
  const timerRef = useRef(0);
  const [timerValue, setTimerValue] = useState(5);

  if (!userLogin?.loggedIn) {
    return <Navigate to={"/login"} />;
  }

  const timerFunction = () => {
    if (timerRef.current) {
      return;
    }
    timerRef.current = setInterval(() => setTimerValue((re) => re - 1), 1000);
  }

  const toasterHideandShow = () => {
    if (document.getElementsByClassName('toast')[0].className.includes('show')) {
      document.getElementsByClassName('toast')[0].classList.add('hide');
      document.getElementsByClassName('toast')[0].classList.remove('show');
    } else {
      document.getElementsByClassName('toast')[0].classList.add('show');
      document.getElementsByClassName('toast')[0].classList.remove('hide');
    }
  }

  const logOut = () => {
    logOutUser().then((res) => {
      if (res?.code === "200") {
        // setToasterContent('User Loggedout Successfully');
        toast.success(`${res?.message}`, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        });
      }
    });
    // timerFunction();
    // toasterHideandShow();
    setTimeout(() => {
      dispatch(initialSessionCheck());
      dispatch(initialWishListCheck());
      dispatch(initialCartListCheck());
      navigate("/");
      // toasterHideandShow();
      // setTimerValue(5);
      // clearInterval(timerRef.current);
      // timerRef.current = 0;
    }, 5000);
  }

  return (
    <section>
      <div className="container">
        {/* row */}
        <div className="row">
          {/* col */}
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center d-md-none py-4">
              {/* heading */}
              <h3 className="fs-5 mb-0">Account Settings</h3>
              {/* button */}
              <button
                className="btn btn-outline-gray-400 text-muted d-md-none btn-icon btn-sm ms-3"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#navbar-default-layout"
                aria-controls="navbar-default-layout"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-text-indent-left fs-3" />
              </button>
            </div>
          </div>
          {/* col */}

        <div className="border-end col-12 col-lg-2 col-md-3 d-md-block d-none pe-0">
          <div className="pt-10 pe-lg">
            <ul className="nav flex-column nav-pills nav-pills-dark">
              <li className="nav-item" key={"/account/order"}>
                  <Link
                  className="nav-link"
                    to="/account/order"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Your Orders
                  </Link>
                </li>
                <li className="nav-item" key={"setting"}>
                  <Link
                    className="nav-link"
                    to="setting"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Settings
                  </Link>
                </li>
                <li className="nav-item" key={"address"}>
                  <Link className="nav-link" to="address">
                    Address
                  </Link>
                </li>
                <li className="nav-item" key={4}><hr /></li>
                <li className="nav-item w-100 w-lg-auto" key={5}>
                  <button className="nav-link" onClick={logOut}>
                    Log out
                  </button>
                </li>
              </ul>
            </div>
          </div>

          {/* <nav
        className="navbar navbar-expand-lg navbar-light navbar-default py-0 pb-lg-4"
        aria-label="Offcanvas navbar large"
      > */}
          {/* <div className="container"> */}
          <div
            className="offcanvas offcanvas-start"
            tabIndex={-1}
            id="navbar-default-layout"
            aria-labelledby="navbar-defaultLabel"
          >
            <div className="offcanvas-header pb-1">
              <Link to="/">
              <div className="d-flex align-items-center">
                <img
                  src={zebroz_logo}
                  alt="Zebroz"
                  className="w-15 h-15"
                />
                <img
                  src={zebroz_name}
                  alt="Zebroz"
                  className="w-50 position-absolute ms-3"
                />
                </div>
              </Link>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <div>
                <ul className="navbar-nav align-items-center">
                  <li className="nav-item w-100 w-lg-auto" data-bs-dismiss="offcanvas" key={1}>
                    <Link
                      className="nav-link"
                      to="/account/order"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"

                    >
                      Your Orders
                    </Link>
                  </li>
                  <li className="nav-item w-100 w-lg-auto" data-bs-dismiss="offcanvas" key={2}>
                    <Link
                      className="nav-link"
                      to={"setting"}

                      // data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Settings
                    </Link>
                  </li>
                  <li className="nav-item w-100 w-lg-auto" data-bs-dismiss="offcanvas" key={3}>
                    <Link className="nav-link" to="address">
                      Address
                    </Link>
                  </li>
                  <li className="nav-item w-100 w-lg-auto" data-bs-dismiss="offcanvas" key={4}>
                    <button className="nav-link" onClick={logOut}>
                      Log out
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </nav> */}


          <div className="col-lg-10 col-md-9 col-12">
            <div className="py-6 p-md-6 p-lg-10">
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      {/* <div class="col-12 position-fixed top-0 end-0 p-3" style={{zIndex: 11}}>
        <div id="liveToast" class="float-end toast hide" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-header">
            {/* <img src="..." class="rounded me-2" alt="..."/> */}
      {/* <strong class="me-auto">Status Message</strong>
              <small>Will disappear in {timerValue} seconds</small> */}
      {/* <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button> */}
      {/* </div>
          <div class="toast-body">
            {toasterContent}
          </div>
        </div>
      </div> */}

      <ToastContainer />

    </section>
  );
};
